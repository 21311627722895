export const SessionKeys = {
  Elastic: 'EVINATURE_ELASTIC_ID',
  Mongo: 'EVINATURE_MONGO_ID',
  Starter: 'EVINATURE_STARTER',
  Navigated: 'EVINATURE_NAVIGATED',
  Results: 'EVINATURE_RESULTS',
  DimensionResults: 'EVINATURE_DIMENSIONS_RESULTS',
  Factors: 'EVINATURE_FACTORS',
  Products: 'EVINATURE_PRODUCTS',
  Timeline: 'EVINATURE_TIMELINE',
  ProToken: 'EVINATURE_PRO_TOKEN',
  ProRef: 'EVINATURE_PRO_USER_REF',
};
