import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirtyService {
  public SAVE_STATE = 'SAVED';

  constructor() {
  }

  public _dirty = false;

  public _saving = false;

  public _error = false;

  public detect: EventEmitter<any> = new EventEmitter<any>();

  public error: EventEmitter<any> = new EventEmitter<any>();

  public dirty: EventEmitter<boolean> = new EventEmitter<boolean>();

  public saving: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onInit: EventEmitter<any> = new EventEmitter<any>();

  public onChange: EventEmitter<any> = new EventEmitter<any>();

  public onRefresh: EventEmitter<any> = new EventEmitter<any>();

  public saveEmitter: EventEmitter<any> = new EventEmitter<any>();

  public enableSaveEmitter: EventEmitter<any> = new EventEmitter<any>();

  public save() {
    this.saveEmitter.emit();
  }

  public enableSave(state: any) {
    this.enableSaveEmitter.emit(state);
  }

  public refresh() {
    this.onRefresh.emit();
  }

  public init() {
    this.onInit.emit(true);
  }

  public change(map: any) {
    this.onChange.emit(map);
  }

  public setDirty() {
    this._dirty = true;
    this.dirty.emit(true);
  }

  public clearDirty() {
    this._dirty = false;
    this.dirty.emit(false);
    this.clearError();
  }

  public setError() {
    this._error = true;
    this.error.emit(true);
  }

  public clearError() {
    this._error = false;
    this.error.emit(false);
  }

  public setSaving() {
    this._saving = false;
    this.saving.emit(true);
  }

  public clearSaving() {
    this._saving = false;
    this.saving.emit(false);
  }

  public detectChanges() {
    if (!this._dirty) {
      this.detect.emit();
    }
  }
}
