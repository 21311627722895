import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
  @Input('countdown') countdown?: number = 0;

  @Input('close') close = false;

  @Input('closeOnly') closeOnly = false;

  @Output('complete') complete: EventEmitter<any> = new EventEmitter<any>();

  seconds = 10;

  ngOnInit(): void {
    this.seconds = this.countdown || 0;
    const interval = setInterval(() => {
      this.seconds = --this.seconds;
      if (this.seconds === 0) {
        clearInterval(interval);
        this.complete.emit();
      }
    }, 1000);
  }

  closeCounter() {
    this.complete.emit();
  }
}
