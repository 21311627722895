import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { environment } from '@environments/environment';
import { Question } from '@models/question.model';
import { DirtyService } from '@services/dirty.service';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { LoaderService } from '@services/loader.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Options } from 'ngx-slider-v2';

@Component({
  selector: 'app-value-slider',
  templateUrl: './select-value-slider.component.html',
  styleUrls: ['./select-value-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SelectValueSliderComponent extends PropertiesBaseComponent implements OnChanges {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    public gtmService: GoogleTagManagerService,
    public loaderService: LoaderService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      switch (keys) {
        case 'k-ArrowLeft':
          if (this.value >= Number(this.options.floor) + Number(this.options.step)) {
            this.value -= Number(this.options.step);
          }

          break;
        case 'k-ArrowRight':
          if (this.value <= Number(this.options.ceil) - Number(this.options.step)) {
            this.value += Number(this.options.step);
          }
          break;
        case 'k-Enter':
          if (this.setValue(this.value)) {
            this.resultsService.nextPage();
          }
          break;
      }
    });

    this.subscriptions.add(subscription);
  }

  @Input() set question(value: Question | null) {
    if (value) {
      this._question = value;
      this._properties = value.properties || { min: 0, max: 100 };
      this._properties.text1 = this._properties.text1 || 'Continue';
      this.options = {
        floor: this._properties.min,
        step: this._properties.step,
        ceil: this._properties.max,
        showTicks: true,
        showTicksValues: this._properties.showTicks,
      };
      this.onLoad.next(this._question);
      if (value !== undefined) {
        const stepResults = this.resultsService.getResultsForQuestion(value._id);
        if (stepResults) {
          this.setValue(stepResults);
          this.canContinue = true;
        } else {
          this.value = 0;
        }
      }

      this.loaderService.isReady();
    }
  }

  get question() {
    return this._question ? this._question : null;
  }

  public value = 0;

  moveNext() {
    if (this.question?.ga_event && environment.production) {
      this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
    }
    this.resultsService.nextPage();
  }

  public options: Options = {
    floor: 0,
    ceil: 200,
    step: 10,
    showTicks: true,
    showTicksValues: true,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  public setValue(value: any) {
    this.value = value;
    this.canContinue = true;
    this.onUpdate.next({ questionId: this._question?._id, result: value });
    return true;
  }
}
