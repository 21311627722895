import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionGroup } from '@models/question-group.model';
import { SessionKeys } from '@services/consts';
import { SessionStorageService } from '@services/sessionStorage.service';
import { TimelineService } from '@services/timeline/timeline.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-progress-pro',
  templateUrl: './progress-pro.component.html',
  styleUrls: ['./progress-pro.component.scss'],
})
export class ProgressProComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private timelineService: TimelineService,
    public sessionStorageService: SessionStorageService,
  ) {
    this.subscriptions.add(
      this.route.params.subscribe(() => {
        this.loadGroups();
      }),
    );
  }

  public subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input('steps') public steps: any[] = [];

  _progress: any[] = [];

  visibleProgress: any[] = [];
  get progress() {
    return this._progress;
  }

  @Input('progress') set progress(value: any[]) {
    this._progress = value;
    this.loadGroups();
  }

  @Input('activeStep') public activeStep?: any;

  @Input('activeStepIndex') public activeStepIndex?: number;

  public ngOnInit(): void {}

  resultsForMatch: any = {};

  public loadGroups(): void {
    // load the results
    this.resultsForMatch = this.sessionStorageService.getItem(SessionKeys.Results) || {};

    this.visibleProgress = [];
    if (this._progress) {
      this._progress.forEach(group => {
        group.visible = false;
        group.steps.forEach((indexedStep: { questionId: string; index: number }) => {
          if (Number(indexedStep.index) === Number(this.activeStepIndex)) {
            group.visible = true;
          } else if (this.timelineService.indexExist(indexedStep.index)) {
            // (this.resultsForMatch[`${indexedStep.index + 1}_${indexedStep.questionId}`]) {
            group.visible = true;
          }
        });
      });
      this.visibleProgress = this._progress.filter(g => g.visible);
    }
  }

  public getFillPercentage(group: any) {
    if (group.steps) {
      const qCount = group.steps.length;
      let fillCount = 0;
      group.steps.forEach((indexedStep: { questionId: string; index: number }) => {
        if (Number(indexedStep.index) <= Number(this.activeStepIndex)) {
          fillCount++;
        }
      });

      if (fillCount) {
        return `${Math.ceil((fillCount / qCount) * 100)}%`;
      }
    }
    return 0;
  }

  public filterByGroup(group: QuestionGroup, qId: string) {
    try {
      const filteredSteps = this.steps.filter(step => step.question.group._id === group._id && step.question._id === qId);
      return filteredSteps;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getActiveStepStyle(group: { steps: { questionId: string }[] }, questionId: string) {
    try {
      return group.steps.some((step: any) => step.questionId === questionId);
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
