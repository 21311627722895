import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LoaderService } from '@services/loader.service';
import { environment } from '@environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-enter-value',
  templateUrl: './enter-value.component.html',
  styleUrls: ['./enter-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EnterValueComponent extends PropertiesBaseComponent implements AfterViewInit {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    public gtmService: GoogleTagManagerService,
    public loaderService: LoaderService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);
    this.subscriptions.add(
      this.route.params.subscribe(async () => {
        if (this._properties.dataSets) {
          this.toggleUnits(this._properties.dataSets[0]);
        }
      }),
    );
    this.subscriptions.add(
      this.onLoad.subscribe(question => {
        if (this._properties.dataSets) {
          this.toggleUnits(this._properties.dataSets[0]);
        }
        const enteredValue = this.resultsService.getResultsForQuestion(question._id);
        this.EnteredValue = enteredValue;
        if (this._properties.inputType === 'multiline') {
          this.notValid = false;
          console.info('Log: canContinue', 'true');
          this.canContinue = true;
        }
      }),
    );

    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      this.inputData?.nativeElement.focus();
      switch (keys) {
        case 'k-Enter':
          if (!this.EnteredValue) {
            this.notValid = true;
          }

          if (this.updateProps()) {
            this.resultsService.nextPage();
          }
          break;
      }
    });

    this.subscriptions.add(subscription);
  }
  public checkedOptIn = false;
  childhoodYear = new Date().getFullYear() - 13;
  notValid = false;

  @ViewChild('inputData') public inputData?: ElementRef;

  public matcher = new MyErrorStateMatcher();

  public EnteredValue: any;

  public ngAfterViewInit() {
    this.inputData?.nativeElement.focus();
    this.updateProps(true);
  }

  scrollInterval: any;

  noScroll() {}

  public ngOnInit() {
    this.loaderService.isReady();
  }

  toggleUnits(dataSet: any) {
    this._properties.dataSets.forEach((element: any) => {
      element.active = false;
    });
    dataSet.active = true;
  }

  public isValid(text: any) {
    if (!text) {
      return false;
    }
    switch (this._properties.inputType) {
      case 'email':
        // eslint-disable-next-line no-useless-escape
        const regularExpression =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regularExpression.test(String(text).toLowerCase());
      case 'date':
        if (Number(text) > this.childhoodYear && !this.checkedOptIn) {
          return false;
        }

        return Number.isInteger(Number(text));
      case 'number':
        return Number.isInteger(Number(text));
    }
    return true;
  }

  @Output() public paging: EventEmitter<boolean> = new EventEmitter();

  moveNext() {
    if (this.updateProps()) {
      if (this.question?.ga_event && environment.production) {
        this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
      }

      this.resultsService.nextPage();
    }
  }

  public updateProps(silent = false) {
    if (this._properties.inputType === 'multiline') {
      this.notValid = false;
    }
    const validationResult = this.isValid(this.EnteredValue);
    if (validationResult) {
      this.notValid = false;
      this.canContinue = true;
      this.changeDetectorRef.detectChanges();
      this.onUpdate.next({
        questionId: this.question?._id,
        result: this.EnteredValue,
      });

      return true;
    } else {
      this.canContinue = false;
      this.changeDetectorRef.detectChanges();
      this.onUpdate.next({
        errors: this.EnteredValue,
      });
      if (!silent) {
        this.notValid = true;
      }
      return false;
    }
  }
}
