<ng-template #template>
  <a class="close-button cursor-btn" id="dialog-close-btn">
    <div class="countrer-close">
      <div class="child counter-part" [ngClass]="{ hidden: closeBtnVisible }">
        <app-countdown [close]="true" [closeOnly]="true" (complete)="closeDialog()"></app-countdown>
      </div>
    </div>
  </a>
  <div class="modal-body" *ngIf="activeTextBubble">
    <div class="title-flex">
      <img src="/assets/icons/light-bulb.svg" alt="light-bulb" class="light-bulb" />

      <div class="title2 title-space" [innerHTML]="activeTextBubble.prefix"></div>
    </div>

    <div class="title3" [innerHTML]="activeTextBubble.info"></div>
  </div>
</ng-template>

<div *ngIf="optionsArray" class="panel centered" #container>
  <table>
    <thead>
      <tr>
        <th class="col-6"></th>
        <ng-container *ngFor="let subDimension of _properties.subDimensions">
          <th class="col-1 capital text-center">{{ subDimension.title }}</th>
          <th class="col-1"></th>
        </ng-container>
      </tr>
    </thead>
    <tbody
      [ngClass]="{ small: _properties.subDimensions?.length === 1, active: optionsArray[i] && optionsArray[i].isActive }"
      *ngFor="let option of optionsArray; let i = index"
      (click)="setActiveOptionHighlight(i)"
    >
      <tr>
        <td class="med-label col-6">{{ optionsArray[i].label }}</td>
        <ng-container *ngFor="let subDimension of _properties.subDimensions">
          <td class="col-1 text-center">
            <input
              
              [title]="optionsArray[i].label"
              class="form-check-input check-lg"
              type="checkbox"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="optionsArray[i][subDimension.name]"
              id="{{ subDimension.name + i }}"
              (change)="showModalForOption(i, optionsArray[i], subDimension.name)"
            />
          </td>
          <td class="col-1"></td>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <div class="center">
    <button id="actionButton" [disabled]="!canContinue" class="btn btn-primary btn-lg" (click)="moveNext()" [innerHTML]="_properties.text1"></button>
  </div>
</div>
