<div class="step-frame">
  <div *ngIf="data && data.steps" class="navigation">
    <app-navigation [page]="activeStepIndex" (paging)="movePaging($event)" [step]="activeStep"></app-navigation>
    <app-progress-pro [steps]="data.steps" [activeStepIndex]="activeStepIndex" [activeStep]="activeStep" [progress]="data.progress"></app-progress-pro>
  </div>

  <div class="slide-container" [@routeAnimations]="getRouteAnimation(outletref)">
    <router-outlet #outletref="outlet"></router-outlet>
  </div>
  <app-factor-debugger *ngIf="debugMode" [proId]="proId"></app-factor-debugger>
</div>
<app-pro-results [steps]="data.steps" *ngIf="debugMode && data && data.steps" [proId]="proId" [activeStep]="activeStep" [editUrl]="editUrl"></app-pro-results>
