<ng-template #tokenEmailValidationDialog>
  <div class="modal-header">
    <h2>
      <span alt="" class="logo-small"></span>
      Welcome back!
    </h2>
  </div>
  <div class="modal-body">
    <div class="center">
      <p class="dialog-text" *ngIf="!emailValid">
        You are about to continue your questainaire,<br />
        please enter your email below:<br />
      </p>

      <div class="dialog-text-bold" *ngIf="emailValid">
        <p *ngIf="!completionNotice">We saved your place, click "Resume" to continue from where you left off. If you wish to start over, click "Start over".</p>

        <p *ngIf="completionNotice">{{ completionNotice }}</p>

        <div class="btn-group buttons-group">
          <button id="dialogActionButton2" *ngIf="!completionNotice || !proExpiredMode" class="btn continue-btn" (click)="resumePro($event)" [innerHTML]="'Resume'"></button>
          &nbsp;
          <button id="proExpiredMode" class="btn continue-btn" (click)="resetProAndContinue($event)" [innerHTML]="'Start over'"></button>
        </div>
      </div>

      <div class="input-group" *ngIf="!emailValid">
        <!-- (keydown)="resetEmailValidation($event)" -->
        <input
          name="inputData"
          [ngClass]="{ 'is-invalid': emailError }"
          [(ngModel)]="userEmail"
          #inputData
          type="email"
          class="form-control form-control-lg form-email"
          [email]="true"
        />
        <div class="input-group-append">
          <button
            id="dialogActionButton2"
            class="btn btn-primary btn-email-validation btn-outline-secondary"
            *ngIf="!proExpiredMode"
            (click)="vaidateEmailAndContinue($event)"
            [title]="emailError ? 'Revalidate Email' : 'Validate Email'"
          >
            <em
              class="icon-primary"
              [ngClass]="{
                'icon-check': !validatingEmailLoader,
                'spinner-border': validatingEmailLoader && !emailError,
              }"
            ></em>
          </button>
        </div>

        <p class="dialog-text-bold" *ngIf="emailError">Email not found, please use the same email address you have provided before, or start over.</p>
        <div class="align-center">
          <button id="clearDataForReset" class="btn continue-btn" *ngIf="emailError" (click)="clearDataForReset($event)" [innerHTML]="'Start over'"></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
