const isDev = location.href.includes('.dev');

export const environment = {
  production: true,
  adminUrl: 'https://admin.evinature.com',
  siteUrl: isDev ? 'https://dev.evinature.com' : 'https://evinature.com',
  cartPage: 'cart-page',
  shop: 'woocommerce',
  isActive: true,
};
