<div *ngIf="question && activeOption !== undefined" class="centered">
  <p [innerHTML]="sanitizedProperties.label" class="title3"></p>
  <div [innerHTML]="sanitizedProperties.info" class="title4"></div>
</div>

<div class="center">
  <button *ngIf="_properties.btnText" id="actionButton" class="btn btn-primary btn-lg" (click)="moveNext()" [innerHTML]="_properties.btnText"></button>
  <app-countdown *ngIf="_properties.countdown" [countdown]="_properties.countdown" (complete)="moveNext()"></app-countdown>
  <app-confetti *ngIf="_properties.confetti"></app-confetti>
</div>
