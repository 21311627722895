<div *ngIf="question && debugMode">
  <div class="row">
    <div class="col-8">
      <button (click)="dryRun()" class="btn btn-sm" [ngClass]="{ 'btn-success': dryRunResult, 'btn-warning': dryRunResult === false }">Test all rules</button>
      <table class="table" [cellPadding]="5" [cellSpacing]="3" [border]="1">
        <thead class="thead-dark">
          <tr>
            <th>Valid</th>
            <th *ngFor="let factor of factors; let factorIndex = index">
              {{ factor }}
            </th>
            <th>Products</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rule of rules; let i = index">
            <td>
              <em class="icon-check btn-success big" *ngIf="rule.applicable"></em>
            </td>

            <td *ngFor="let factor of factors; let factorIndex = index">
              <ng-container *ngIf="rule.factors && rule.factors[factor]">
                <ng-container *ngIf="rule.factors && rule.factors[factor] && rule.factors[factor].operatorSymbol?.startsWith('bt')">
                  {{ rule.factors[factor].operatorValue }}
                  {{ operatorSymbols[rule.factors[factor].operatorSymbol] }}
                  {{ rule.factors[factor].operatorValue2 }}
                </ng-container>

                <ng-container *ngIf="rule.factors && rule.factors[factor] && !rule.factors[factor].operatorSymbol?.startsWith('bt')">
                  {{ operatorSymbols[rule.factors[factor].operatorSymbol] }} {{ rule.factors[factor].operatorValue }}
                </ng-container>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let ruleValue of rule.values; let i = index">
                <a placement="left" class="w-100 btn btn-info btn-sm row" type="button" *ngIf="ruleValue">
                  <span class="col left"> {{ ruleValue.name }}</span>
                  <span class="col right">
                    <small class="badge badge-black"> {{ ruleValue.dosagePills }}P * {{ ruleValue.quantity }}Q </small>
                  </span>
                </a>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-4" style="position: fixed; right: 0">
      <div *ngFor="let factor of factors" class="row">
        <div class="col-6">{{ factor }}</div>
        <div class="col-6">
          <ng-container *ngIf="results[factor] || results[factor] === 0">
            <input type="number" class="form-control" [(ngModel)]="results[factor]" (change)="dryRun()" />
          </ng-container>

          <ng-container *ngIf="!results[factor] && results[factor] !== 0">
            <button class="btn form-control" (click)="results[factor] = 0">+</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="center">
    <button id="actionButton" class="btn btn-primary btn-lg" (click)="moveOn()">Continue</button>
  </div>
</div>
