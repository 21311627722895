import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Exception } from '@models/exceptions.model';
import { Pro, ProStep } from '@models/pro.model';
import { firstValueFrom, Subject } from 'rxjs';
import { ConditionService, ConditionStateResult } from './condition.service';
import { SessionKeys } from './consts';
import { SessionStorageService } from './sessionStorage.service';
import { ProRouterService } from './pro-router.service';

export type StepData = { id: string; step_id: number };
@Injectable({
  providedIn: 'root',
})
export class ProService {
  public serviceUrl = '/api/pro-play';

  constructor(
    private http: HttpClient,
    private conditionService: ConditionService,
    public sessionStorageService: SessionStorageService,
    public proRouterService: ProRouterService,
  ) {}

  public activePro: Pro | null = null;

  public get canSkipValue() {
    return this._canSkip;
  }

  public set canSkipValue(value: boolean) {
    this._canSkip = value;
    this.canSkip$.next(value);
  }

  _canSkip = false;

  public canSkip$: Subject<boolean> = new Subject<boolean>();

  public onStepChanged$: Subject<StepData> = new Subject<StepData>();

  public async get(id: string): Promise<Pro> {
    try {
      this.activePro = await firstValueFrom(this.http.get<Pro>(`${this.serviceUrl}/${id}`));
      return this.activePro;
    } catch (err) {
      if ((err as any)?.error?.message === 'not_active') {
        document.location.href = '/';
      }
      console.error('Log: error in get pro', err);
      throw err;
    }
  }

  public query(): Promise<any> {
    return firstValueFrom(this.http.get(this.serviceUrl));
  }

  public publishClientEvent(eventName: string, eventData: any) {
    return firstValueFrom(this.http.post(`${this.serviceUrl}/publish-client-event/${eventName}`, eventData));
  }

  public getResultForDimensions(property: string) {
    return firstValueFrom(this.http.get(`${this.serviceUrl}/compare/cross-dimension?property=${property}`));
  }

  public getIsFirstOrder(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.serviceUrl}/compare/user-orders`));
  }

  public getResultForExceptions() {
    return firstValueFrom(this.http.get(`${this.serviceUrl}/compare/cross-dimension-exceptions`));
  }

  public getExceptionData(id: string): Promise<Exception> {
    return firstValueFrom(this.http.get<Exception>(`${this.serviceUrl}/product-exceptions/${id}`));
  }

  public fixSingleUserEmail(data: any, proId?: string): Promise<any> {
    return firstValueFrom(this.http.post<any>(`${this.serviceUrl}/troubleshooting/${proId}/fix-single-user/`, data));
  }

  public visible(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.serviceUrl}/all`));
  }

  public async setResultData(id: string, data: any) {
    const tokenResult: any = await firstValueFrom(this.http.post(`${this.serviceUrl}/${id}`, data));
    if (tokenResult && tokenResult.token !== 'unchanged' && tokenResult.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, tokenResult.token);
    } else {
      console.error('missing token3');
    }

    return tokenResult;
  }

  public async updateResultData(id: string, elasticRecordId: string, data: any) {
    const tokenResult: any = await firstValueFrom(this.http.patch(`${this.serviceUrl}/${id}/record/${elasticRecordId}`, data));

    if (tokenResult && tokenResult.token !== 'unchanged' && tokenResult.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, tokenResult.token);
    } else {
      console.error('missing token4');
    }

    return tokenResult;
  }

  public async load(pro_id: string, step_id: number): Promise<Pro> {
    if (step_id > 0 && this.activePro !== null && pro_id === this.activePro._id) {
      return Promise.resolve(this.activePro);
    } else {
      // await this.tokenService.create({});
      this.canSkip$.next(false);
      this.activePro = null;
      this.activePro = await this.get(pro_id);
    }
    return this.activePro;
  }

  public getStep(step_id: number) {
    const foundStep = this.activePro?.steps?.[step_id];
    return foundStep;
  }

  async getNextUnSkippedStep(step_id: number) {
    let currentStep = this.activePro?.steps[step_id];
    if (currentStep && this.activePro) {
      let currentIndex: number = this.activePro.steps.indexOf(currentStep);

      const uptoHereSteps: ProStep[] = this.activePro.steps; //.slice(currentIndex);
      for (let index = currentIndex; index < uptoHereSteps.length; index++) {
        if (!uptoHereSteps[index].skip) {
          currentIndex = Number(step_id) + Number(index);
          currentStep = uptoHereSteps[index];
          currentIndex = this.activePro.steps.indexOf(currentStep);
          console.info('Log: the next step is', currentIndex, index, step_id);
          break;
        }
      }
      console.info('Log: next unskipped', currentStep, currentIndex);
      return { currentStep, currentIndex };
    }
    return null;
  }

  public getByQuestionId(questionId: string, startIndex = 0) {
    const foundSteps = this.activePro?.steps.filter((step, index) => {
      return index > startIndex && step.questionId === questionId;
    });

    console.info('Log: get by questionId', foundSteps);
    if (foundSteps) {
      const stepIndex: number = this.activePro?.steps.indexOf(foundSteps[0], startIndex) || -1;
      return { foundStep: { proId: this.activePro?._id }, stepIndex };
    }
    return null;
  }

  public getFirstStepOfPro(proId: string, startIndex?: number) {
    const orgProId = this.activePro?._id;

    const proSteps = this.activePro?.steps.filter(step => step.proId === proId);
    if (proSteps && proSteps.length > 0) {
      const stepIndex: number = this.activePro?.steps.indexOf(proSteps[0], startIndex) ?? -1;
      return { foundStep: { proId: orgProId }, stepIndex };
    } else {
      // find the right step
      return { foundStep: { proId }, stepIndex: 7 };
    }
  }

  async handleConditionsLoad(stepConditions: any[], isRules?: boolean) {
    const conditionsDic: any = {};
    stepConditions.forEach(condition => {
      if (condition.conditionType === 'Rule' || isRules) {
        conditionsDic[condition] = condition;
      }
    });

    if (Object.values(conditionsDic).length) {
      const proResolution: Record<string, ConditionStateResult> = await this.proRouterService.resolveProConditions(conditionsDic);
      this.conditionService.setConditions(proResolution);
      return Object.values(proResolution);
    }
    return null;
  }

  async shouldIstayOrShouldIskip(step_id: number) {
    console.info('shouldIstayOrShouldIskip');
    const currentStep = this.activePro?.steps[step_id];
    if (currentStep && this.activePro) {
      const currentIndex: number = this.activePro.steps.indexOf(currentStep);

      // check the step conditions
      if (currentStep.conditions) {
        console.info('Log: test step next conditions', currentStep.conditions);
      }

      const ruleConditions = currentStep.conditions?.filter((c: string) => {
        return this.conditionService.conditionsDic[c] && this.conditionService.conditionsDic[c].conditionType === 'Rule';
      });

      let rules: any = [];
      if (ruleConditions) {
        console.info('Log: calculating next step with conditions', ruleConditions);
        rules = await this.handleConditionsLoad(ruleConditions, true);

        const conditionsResult = this.conditionService.parseStepConditions(rules);
        if (conditionsResult) {
          console.info('Log: calculation result', conditionsResult);
          return { currentStep, currentIndex };
        }

        return this.getNextUnSkippedStep(currentIndex + 1);
      }
    }
    return null;
  }
}
