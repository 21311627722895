/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
  public handlers: Record<string, DetachedRouteHandle | null> = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.routeConfig?.path) {
      this.handlers[route.routeConfig.path] = null;
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig?.path) {
      return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
    } else {
      return false;
    }
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) {
      return null;
    }
    return route.routeConfig.path ? this.handlers[route.routeConfig.path] : null;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;
  }
}
