import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public _loading = false;
  public _hidden = false;
  isReady() {
    console.info('Log: isReady');
    setTimeout(() => {
      this.loading$.next(false);
      this.showContent();
    }, 100);
  }

  notReady() {
    console.info('Log: notReady');
    setTimeout(() => {
      this.loading$.next(true);
    }, 100);
  }

  hideContent() {
    this.curtain$.next(true);
  }

  showContent() {
    this.curtain$.next(false);
  }

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public curtain$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
