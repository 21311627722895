import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

const left = [
  query(':leave, :enter', style({ position: 'absolute', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.5s ease-in-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.5s ease-in-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
  ]),
];

export const slideInAnimation = trigger('routeAnimations', [
  state('void', style({ position: 'absolute', width: '100%' })),
  state('*', style({ position: 'absolute', width: '100%' })),

  state('next', style({ position: 'absolute', width: '100%' })),
  transition('* => next', right),

  state('prev', style({ position: 'absolute', width: '100%' })),
  transition('* => prev', left),
]);
