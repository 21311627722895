import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';

enum BMI_OPTIONS {
  UNDERWEIGHT = 'Underweight',
  JUSTFINE = 'just fine',
  MORE_THAN_OVERWEIGHT = 'more than overweight',
  OVERWIEGHT = 'overweight',
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-bmi-calc',
  templateUrl: './bmi-calc.component.html',
  styleUrls: ['./bmi-calc.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BMICalcComponent extends PropertiesBaseComponent implements OnChanges, OnInit {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public pagingService: PagingService,
    public keyServiceRef: KeyPressDistributionService
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      switch (keys) {
        case 'k-ArrowDown':
          break;
        case 'k-ArrowUp':
          break;
        case 'k-Enter':
          if (this.updateProps()) {
            // this.resultsService.nextPage();
          }
          break;
      }
    });
    this.subscriptions.add(subscription);

    this.subscriptions.add(
      this.onLoad.subscribe(question => {
        const stepResults = this.resultsService.getResultsForQuestion(question._id);
        this._properties.bmi = stepResults.value;
        this._properties.units = stepResults.units;
        this._properties.weight_lb = stepResults.weight_lb;
        this._properties.height_foot = stepResults.height_foot;
        this._properties.height_inch = stepResults.height_inch;
        this._properties.weight_kg = stepResults.weight_kg;
        this._properties.height_cm = stepResults.height_cm;
      })
    );
  }

  public results: Record<string, any> = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  public ngOnInit() {
    this._properties.units = this._properties.units || 'metric';
    this._properties.bmi = this._properties.bmi || 0;
    this._properties.weight_lb = this._properties.weight_lb || 0;
    this._properties.height_foot = this._properties.height_foot || 0;
    this._properties.height_inch = this._properties.height_inch || 0;
    this._properties.weight_kg = this._properties.weight_kg || 0;
    this._properties.height_cm = this._properties.height_cm || 0;
  }

  catClass = '';

  catTitle = '';

  public calc() {
    if (this._properties.units === 'imperial') {
      if (this._properties.weight_lb > 0) {
        const w_kg = this._properties.weight_lb * 0.453592;
        const h_m = this._properties.height_foot * 0.3048 + this._properties.height_inch * 0.0254;
        this._properties.bmi = (h_m ? w_kg / (h_m * h_m) : 0.0).toFixed(1);
      }
    } else if (this._properties.weight_kg > 0) {
      this._properties.bmi = (this._properties.height_cm ? (this._properties.weight_kg * 10000) / (this._properties.height_cm * this._properties.height_cm) : 0.0).toFixed(1);
    }

    this.colorBmi(this._properties.bmi);
  }

  changeValues(mode: string) {
    if (mode === 'metric') {
      this._properties.weight_kg = Math.round(this._properties.weight_lb ? this._properties.weight_lb * 0.453592 : this._properties.weight_kg);
      this._properties.height_cm = Math.round(
        this._properties.height_foot ? this._properties.height_foot * 0.3048 + this._properties.height_inch * 0.0254 : this._properties.height_cm
      );
    } else {
      this._properties.weight_lb = Math.round(this._properties.weight_kg ? this._properties.weight_kg / 0.453592 : this._properties.weight_lb);
      let inches: number = this._properties.height_cm / 0.393700787;
      const feet = Math.floor(inches / 12);
      inches %= 12;
      this._properties.height_foot = feet;
      this._properties.height_inch = inches.toFixed(0);
      this.calc();
    }
  }

  colorBmi(value: number) {
    if (value) {
      if (value <= 24 && value >= 19) {
        this.catClass = 'success';
        this.catTitle = BMI_OPTIONS.JUSTFINE;
        this.updateProps();
      } else if (value < 19 && value > 0) {
        this.catClass = 'danger';
        this.catTitle = BMI_OPTIONS.UNDERWEIGHT;
        this.updateProps();
      } else if (value > 24 && value < 70) {
        this.catClass = 'danger';
        this.catTitle = BMI_OPTIONS.OVERWIEGHT;
        this.updateProps();
      } else if (value > 70) {
        this.catClass = 'danger';
        this.catTitle = BMI_OPTIONS.MORE_THAN_OVERWEIGHT;
        this.updateProps();
      } else {
        this.catClass = 'default';
        this.catTitle = 'Unknown';
      }
    } else {
      this.catClass = 'default';
      this.catTitle = 'Unknown';
    }
  }

  public updateProps() {
    this.onUpdate.next({
      questionId: this.question?._id,
      result: {
        units: this._properties.units,
        value: Number(this._properties.bmi),
        weight_lb: this._properties.weight_lb,
        height_cm: this._properties.height_cm,
        weight_kg: this._properties.weight_kg,
        height_foot: this._properties.height_foot,
        height_inch: this._properties.height_inch,
      },
    });
    return true;
  }

  moveNext() {
    if (this.updateProps()) {
      this.resultsService.nextPage();
    }
  }
}
