import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirtyService } from '@services/dirty.service';
@Component({
  selector: 'app-item-base',
  template: '',
})
export class ItemBaseComponent {
  constructor(public _ngZone: NgZone, public dirtyService: DirtyService, public changeDetectorRef: ChangeDetectorRef, public router: Router, public route: ActivatedRoute) {}

  public DataController: any;
}
