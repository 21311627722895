import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: any;

  constructor(private readonly http: HttpClient) {}

  async loadAppConfig() {
    const appConfig = await firstValueFrom(this.http.get<any>('/api/settings'));
    this.appConfig = appConfig[0];
  }

  getConfig(): any {
    return this.appConfig;
  }
}
