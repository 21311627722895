import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Rule } from '@models/pro.model';
import { DirtyService } from '@services/dirty.service';
import { QuestionType } from '@models/question-type.model';
import { Question } from '@models/question.model';
import { LoaderService } from '@services/loader.service';

@Component({
  selector: 'app-question-properties',
  templateUrl: './question-properties.component.html',
  styleUrls: ['./question-properties.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: QuestionPropertiesComponent,
    },
  ],
})
export class QuestionPropertiesComponent {
  constructor(public _ngZone: NgZone, public changeDetectorRef: ChangeDetectorRef, public loaderService: LoaderService, public dirtyService: DirtyService) {
    this.dirtyService.setDirty();
    // this.loaderService.loading.emit(false);
  }

  @Input('question') set question(value: Question) {
    this._question = value;
  }

  get question() {
    return this._question;
  }

  public _question: Question = {} as any;

  public properties: Record<string, any> = {};

  @Input('type') public type?: QuestionType;

  @Input('rules') public rules: Rule[] = [];

  @Output('valid') public valid: EventEmitter<any> = new EventEmitter<any>();

  @Output('paging') public paging: EventEmitter<any> = new EventEmitter<any>();

  public writeValue(properties: Record<string, any>): void {
    this.properties = properties;
  }

  public moveNext() {
    this.paging.emit(true);
  }

  public updateProps(props: Record<string, any>) {
    this.valid.emit(props);
  }
}
