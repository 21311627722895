<div *ngIf="question">
  <div class="form-group">
    <form>
      <div class="center">
        <input
          name="inputData"
          [(ngModel)]="EnteredValue"
          [placeholder]="_properties.placeholder || ''"
          (keyup)="updateProps(true)"
          (mouseup)="updateProps(true)"
          #inputData
          [type]="_properties.inputType"
          class="form-control form-control-lg form-{{ _properties.inputType }}"
        />

        <div *ngIf="_properties.inputType === 'text' && notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Did you fill in the data?</div>
      </div>
      <div class="center">
        <button *ngIf="_properties.text1" id="actionButton" class="btn btn-primary btn-lg" (click)="moveNext()" [disabled]="!canContinue" [innerHTML]="_properties.text1"></button>
      </div>
    </form>
  </div>
</div>
