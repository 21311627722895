import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-range',
  templateUrl: './select-range.component.html',
  styleUrls: ['./select-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectRangeComponent,
    },
  ],
})
export class SelectRangeComponent implements ControlValueAccessor, OnInit {
  @Input() config: any;

  values: string[] = [];

  value?: string;

  ngOnInit(): void {
    for (let index = Number(this.config.min); index <= Number(this.config.max); index = index + Number(this.config.step)) {
      this.values.push(`${index}`);
    }
  }

  touched?: boolean;

  update() {
    this.onChange && this.onChange({ value: this.value, units: this.config.units });
  }

  onTouched?: () => void;

  onChange?: (data: any) => void;

  onLocalChange(data: any) {
    console.info(data);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public writeValue(value?: { value: string; units: any }): void {
    this.value = value?.value || (value as any);
  }
}
