import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pro } from '@models/pro.model';
import { firstValueFrom } from 'rxjs';
import { SessionKeys } from './consts';
import { SessionStorageService } from './sessionStorage.service';

@Injectable({
  providedIn: 'root',
})
export class ProLocalResultService {
  public serviceUrl = '/api/pro-result';

  constructor(
    private http: HttpClient,
    public sessionStorageService: SessionStorageService,
  ) {}

  public activePro: Pro | null = null;

  public async setResultData(id: string, data: any) {
    const tokenResult: any = await firstValueFrom(this.http.post(`${this.serviceUrl}/${id}`, data));
    if (tokenResult && tokenResult.token !== 'unchanged' && tokenResult.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, tokenResult.token);
    } else {
      console.error('missing token2');
    }

    return tokenResult;
  }

  public async updateResultData(id: string, mongoRecordId: string, data: any) {
    const tokenResult: any = await firstValueFrom(this.http.patch(`${this.serviceUrl}/${id}/record/${mongoRecordId}`, data));

    if (tokenResult && tokenResult.token !== 'unchanged' && tokenResult.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, tokenResult.token);
    } else {
      console.error('missing token1');
    }

    return tokenResult;
  }

  public updateProStatus(proId: string, mongoRecordId: string, data: any) {
    return firstValueFrom(this.http.patch(`${this.serviceUrl}/${proId}/status/${mongoRecordId}`, data));
  }
}
