import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  constructor() {
    const monthArray = [];
    monthArray[0] = 'January';
    monthArray[1] = 'February';
    monthArray[2] = 'March';
    monthArray[3] = 'April';
    monthArray[4] = 'May';
    monthArray[5] = 'June';
    monthArray[6] = 'July';
    monthArray[7] = 'August';
    monthArray[8] = 'September';
    monthArray[9] = 'October';
    monthArray[10] = 'November';
    monthArray[11] = 'December';
    this.months = monthArray;

   const year =  new Date().getFullYear()

    for (let index = 1; index <= 31; index++) {
      this.days.push(index);
    }
    for (let index = year; index >= 1920; index--) {
      this.years.push(index);
    }
  }

  days: number[] = [];

  years: number[] = [];

  months: string[] = [];

  day = 1;

  month = 1;

  year = 0;

  ngOnInit(): void {}

  update(mode: string, value: any) {
    switch (mode) {
      case 'day':
        this.day = value;
        break;
      case 'month':
        this.month = value;
        break;
      case 'year':
        this.year = value;
        break;
    }

    this.onChange && this.onChange(`${this.year}`);
  }

  touched?: boolean;

  onTouched?: () => void;

  onChange?: (data: any) => void;

  onLocalChange(data: any) {
    console.info(data);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public writeValue(date?: string): void {
    if (date) {
      //  const [month, day, year] = date.split('/');
      // this.day = Number(day);
      // this.month = Number(month);
      console.info('year', date, Number(date));
      this.year = Number(date);
    }
  }
}
