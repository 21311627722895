<div *ngIf="question && showNewsletter" class="container">
  <div class="title3" [innerHTML]="_properties.explain | sanitizeHtml"></div>
  <div class="container">
    <div class="center">
      <input
        name="inputData"
        (focus)="noScroll()"
        [(ngModel)]="EnteredValue"
        (keyup)="updateProps()"
        (mouseup)="updateProps()"
        [placeholder]="_properties.placeholder || ''"
        #inputData
        type="email"
        class="form-control form-control-lg form-email"
        [email]="true"
      />
      <div *ngIf="notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Is this your email?</div>
    </div>
    <div style="padding: 0 15px">
      <input id="optin" type="checkbox" class="checkbox" [(ngModel)]="checkedOptIn" (change)="updateProps()" />
      <label for="optin" class="disclaimer inline" [innerHTML]="_properties.disclaimer | sanitizeHtml"> </label>
    </div>

    <div class="center">
      <button
        *ngIf="_properties.text1 && !OTP"
        id="actionButton"
        class="btn btn-primary btn-lg"
        style="width: 300px"
        (click)="moveNext()"
        [disabled]="!canContinue"
        [innerHTML]="_properties.text1"
        [ngClass]="{ active: OTPStepNext }"
      ></button>
    </div>

    <div *ngIf="OTP" class="center has-validation">
      <p class="otp-text">We've sent an email containing an OTP code, copy & paste in here</p>

      <div class="input-group otp-center">
        <input
          type="text"
          [(ngModel)]="OTPCode"
          class="form-control"
          placeholder="Type you OTP code"
          required
          (keyup)="validatePreOTP()"
          [ngClass]="{ 'is-invalid': !OTPRemoteValid && OTPRemoteValid !== undefined, 'is-valid': OTPRemoteValid }"
        />

        <button class="btn btn-outline-secondary btn-info" (click)="resendOTP()">
          <em class="icon-refresh"></em>
        </button>
      </div>

      <div>
        <span class="invalid-feedback" *ngIf="!OTPRemoteValid && OTPRemoteValid !== undefined">{{ OTPError }}</span>
      </div>
      <div class="center" style="margin-top: 10px">
        <button
          *ngIf="_properties.text1"
          id="actionButton"
          class="btn btn-primary btn-lg"
          style="width: 300px"
          (click)="moveNext()"
          [disabled]="!canContinue"
          [innerHTML]="_properties.text1"
          [ngClass]="{ active: OTPStepNext }"
        ></button>
      </div>
    </div>
  </div>
</div>
