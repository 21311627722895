import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProStep } from '@models/pro.model';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NavigationRequestModel } from '@services/navigation.service';
import { ProService } from '@services/pro.service';
import { StarterService } from '@services/starter.service';
import { TokenService } from '@services/token.service';
import { SessionStorageService } from '@services/sessionStorage.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  constructor(
    public resultsService: ResultsService,
    public pagingService: PagingService,
    private router: Router,
    private route: ActivatedRoute,
    private proService: ProService,
    private modalService: BsModalService,
    private starterService: StarterService,
    private tokenService: TokenService,
    public sessionStorageService: SessionStorageService,
  ) {
    this.subscriptions.add(
      this.resultsService.isValid.subscribe(value => {
        this.dontToContinue = !value;
      }),
    );

    this.subscriptions.add(
      this.resultsService.nextPage$.subscribe(() => {
        console.info('Log: nextPage$ - moveNext');
        // high light the button, wait and move
        this.isNextButtonActive = true;
        this.isNextButtonActive = false;
        this.moveNext();
      }),
    );

    this.subscriptions.add(
      this.proService.canSkip$.subscribe(value => {
        this.canSkip = value;
      }),
    );

    this.subscriptions.add(
      this.pagingService.configureEvent.subscribe(configuration => {
        this.navVisibility = configuration;
        this.isNextButtonActive = configuration.next;
        this.dontToContinue = !configuration.next;
      }),
    );

    this.debugMode = this.route.snapshot.queryParams.debug;
  }

  modalRef?: BsModalRef;

  public subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input('page') public page = 0;

  @Input('step') public step: ProStep = {} as ProStep;

  @Input('debugMode') public debugMode = false;

  @Output('paging') public paging: EventEmitter<NavigationRequestModel> = new EventEmitter<NavigationRequestModel>();

  isNextButtonActive = false;

  canSkip = false;

  environment = environment;

  public navVisibility: { next: boolean; prev: boolean } = {
    next: true,
    prev: true,
  };

  public dontToContinue?: boolean;

  public ngOnInit(): void {
    this.canSkip = this.proService.canSkipValue;

    this.page = Number(this.page);
    if (this.page === 0) {
      this.dontToContinue = false; // first time allow
    } else {
      this.dontToContinue = true; // next slides
    }
  }

  @ViewChild('skipTestDialog') modalTemplate?: TemplateRef<any>;

  config = {
    class: 'modal-dialog',
    animated: true,
  };

  async continueSkip() {
    this.closeDialog();

    let lastFrame = 0;

    for (let i = 0; i < (this.proService.activePro?.steps?.length ?? 0); i++) {
      const step = this.proService.activePro?.steps[i];
      lastFrame = i;
      if (step?.question?.type?.name === 'Treatment checkup') {
        break;
      }
    }

    const queryParams = this.debugMode ? { debug: 1 } : {};
    return this.router.navigate([`../${lastFrame}`], { queryParams, relativeTo: this.route });
  }

  closeDialog() {
    this.modalRef?.hide();
  }

  skipToEnd() {
    this.modalRef = this.modalService.show(this.modalTemplate!, this.config);
  }

  public async resetPro() {
    this.resultsService.reset();
    this.sessionStorageService.clear();

    if (this.proService.activePro?._id) {
      await this.tokenService.create({});
      await this.starterService.freshStart(this.proService.activePro._id);
    }
    return this.router.navigate(['../0'], { relativeTo: this.route });
  }

  public moveNext() {
    this.paging.emit({ isNext: true });
    this.dontToContinue = true;
  }

  public movePrev() {
    this.paging.emit({ isNext: false });
  }
}
