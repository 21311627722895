import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  setItem(key: string, value: any): void {
    try {
      sessionStorage.setItem(key, JSON.stringify({ value }));
    } catch (error) {
      console.info(value);
      console.error(error);
    }
  }

  getItem<T>(key: string, otherwise?: T): T | null {
    const data: string | null = sessionStorage.getItem(key);

    if (data !== null) {
      try {
        return JSON.parse(data).value;
      } catch (error) {
        return data as unknown as T;
      }
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  public updateItem(key: string, update: Record<string, unknown>) {
    const existingItem = this.getItem(key) || {};
    Object.assign(existingItem, update);
    this.setItem(key, existingItem);
  }

  public removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  public clear() {
    sessionStorage.clear();
  }
}
