import { NgxSliderModule } from 'ngx-slider-v2';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { LoaderComponent } from '@components/misc/loader/loader.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MomentModule } from 'ngx-moment';

const importModules = [MomentModule, MatInputModule, MatCheckboxModule, MatButtonModule, NgxSliderModule, MatRadioModule, MatCardModule, DragDropModule];
const declarations = [LoaderComponent];
@NgModule({
  declarations,
  imports: [...importModules, ModalModule.forRoot()],
  exports: [...importModules, ...declarations, ModalModule],
})
export class SharedModule {}
