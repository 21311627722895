import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@services/config.service';

@Component({
  selector: 'app-down-time',
  templateUrl: './down-time.component.html',
  styleUrls: ['./down-time.component.scss'],
})
export class DownTimeComponent {
  constructor(public configService: AppConfigService, public readonly router: Router) {
    const interval = setInterval(async () => {
      await this.configService.loadAppConfig();
      if (this.configService.getConfig().enabled) {
        clearInterval(interval);
        await this.router.navigateByUrl(this.proUrl);
      }
    }, 10000);
  }

  proUrl = '/pro/623b1c1623e3d7000ee7a4e6/step/0';
}
