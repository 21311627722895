import { Injectable } from '@angular/core';
import { QuestionStateRule } from '@models/question-state-rule.model';
import { BehaviorSubject } from 'rxjs';

export interface ConditionStateResult {
  name: string;
  state: boolean;
}

@Injectable({ providedIn: 'root' })
export class ConditionService {
  conditionsChecked = false;

  _conditions?: Record<string, ConditionStateResult> = {};

  conditions$ = new BehaviorSubject({});

  conditionsResolved$ = new BehaviorSubject({});

  conditionsDic: Record<string, QuestionStateRule> = {};

  setConditions(conditions: Record<string, ConditionStateResult>) {
    this._conditions = conditions;
    this.conditionsResolved$.next(conditions);
  }

  setAllConditions(conditions: Record<string, any>) {
    this.conditionsDic = conditions;
    this.conditions$.next(this.conditionsDic);
  }

  isCondition(condition: { state: boolean }) {
    if (condition?.state) {
      return true;
    }
    return false;
  }

  validateConditions(conditions?: string[]) {
    if (!conditions?.length) {
      return true;
    }

    return conditions?.map((c: string) => !!this._conditions?.[c]).reduce((a: boolean, b: boolean) => a || b);
  }

  parseStepConditions(conditions?: string[]) {
    console.info('parseStepConditions', conditions);
    if (!conditions?.length) {
      return true;
    }

    return conditions?.map((c: any) => this.isCondition(c)).reduce((a: boolean, b: boolean) => a || b);
  }
}
