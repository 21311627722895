<div class="container-fluid">
  <ul *ngIf="false">
    <li *ngFor="let group of visibleProgress">
      {{ group.name }}

      <ul>
        <li *ngFor="let step of group.steps">{{ step | json }}</li>
      </ul>
    </li>
  </ul>

  <ul class="timeline" id="timeline">
    <li
      class="group-container"
      *ngFor="let group of visibleProgress"
      [ngClass]="{
        active: getActiveStepStyle(group, activeStep?.question._id)
      }"
    >
      <!-- <div class="dots-container">
      <span
        *ngFor="let step of group.steps"
        class="bullet"
        [ngClass]="{
          active: step.questionId === activeStep?.question._id
        }"
      ></span>
    </div> -->
      <div class="progress-bar" [title]="getFillPercentage(group)"><span class="progress-bar-fill" [ngStyle]="{ width: getFillPercentage(group) }"></span></div>
      <div class="group-name">{{ group.name }}</div>

      <!-- <span
      *ngIf="group._id === activeStep?.question.group._id"
      class="page-name"
    >
      {{ activeStep?.question.name }}
    </span> -->
    </li>
  </ul>
</div>
