<div class="container-fluid">
  <ul class="navigation">
    <li class="left">
      <a (click)="movePrev()" aria-role="button" id="back-btn" class="btn back-button" [ngStyle]="{ visibility: page > 0 && navVisibility.prev ? 'visible' : 'hidden' }"
        ><img src="../../assets/icons/arrow-back.svg" alt="" class="navigation-arrow-icon" /> Back</a
      >
    </li>
    <li class="center">
      <a [href]="environment.siteUrl" target="_blank"><img src="../../assets/evinature_logo.svg" alt="" class="logo-svg" /></a>
    </li>
    <li class="right">
      <a (click)="skipToEnd()" aria-role="button" id="skipButton" *ngIf="canSkip" class="btn btn-skip" title="Skip to the end"><em>Skip</em></a>

      <!-- <a (click)="resetPro()" aria-role="button" id="resetButton" class="btn no-pad" title="Restart the assesment"
        ><img src="../../assets/icons/reset.svg" alt="" class="navigation-icon icon-pad" />
      </a> -->
    </li>
  </ul>
</div>

<ng-template #skipTestDialog>
  <a (click)="closeDialog()" class="close-button" id="dialog-close-btn">
    <img src="../../../../assets/icons/close.svg" class="Group-74" alt="" />
  </a>
  <div class="modal-body">
    <div class="center">
      <button id="dialogActionButton" class="btn btn-primary btn-lg" (click)="continueSkip()" [innerHTML]="'Skip to recommendations'"></button>
    </div>
  </div>
</ng-template>
