<a (click)="consoleService.toggleConsole()" class="tabForSlide {{ consoleService.className }}">Debug Console</a>

<div id="fade-in" cdkDrag (cdkDragEnded)="persistConsolePosition($event)" class="box {{ consoleService.className }}" [ngStyle]="consolePos">
  <div>
    <header>
      <a class="btn btn-info" [href]="editUrl" target="_top">Edit</a>
      <button class="btn btn-default"><em class="icon-repeat"></em> Reload</button>
      <button class="btn btn-default" cdkDragHandle><em class="icon-arrows"></em></button>
      <button (click)="consoleService.toggleConsole()" class="btn btn-default pull-right"><em class="icon-close"></em></button>
    </header>
    <div class="scrollable-steps" #scrollcontainer>
      <div class="list-group list-small" *ngIf="_steps">
        <div class="row" [ngClass]="{ active: activeStep && activeStep?.questionId === step.questionId }" *ngFor="let step of _steps; let i = index">
          <div
            aria-label="Group"
            class="group-tag col-1"
            [ngStyle]="{ 'background-color': step.question && step.question.group && step.question.group.color }"
            [title]="step.question && step.question.group && step.question.group.name"
          >
            {{ step.orderIndex }}
            <em class="icon-eye" [ngClass]="{ 'icon-eye': !step.skip, 'icon-eye-slash': step.skip || !step.showInProgress }"></em>
          </div>
          <div aria-label="Question" class="step-name col-9">
            {{ i }}. {{ step.question && step.question.name }}
            <span *ngIf="activeStep?.questionId === step.questionId"><em class="icon-exclamation-circle"></em></span>

            <pre class="pull-left">{{ getResult(step.questionId) }}</pre>
            <ul class="stack" *ngIf="step.conditions && step.conditions.length">
              <li>Conditions{{':'}}</li>

              <li *ngFor="let condition of step.conditions">
                <div *ngIf="conditionsDic[condition]" class="condition-label" [class]="conditionsDic[condition].conditionType">
                  <em
                    [ngClass]="{
                      'icon-chart': conditionsDic[condition].conditionType === 'Rule',
                      'icon-flag': conditionsDic[condition].conditionType === 'Flag',
                      'icon-target1': conditionsDic[condition].conditionType === 'Router'
                    }"
                  ></em>
                  {{ conditionsDic[condition].name }}
                </div>
              </li>
            </ul>
            <ul class="stack" *ngIf="step.rules && step.rules.length">
              <li>Rules:</li>
              <li *ngFor="let rule of step.rules" [title]="rule.type">
                <span class="badge badge-primary rule-box" *ngIf="rule.type === 'question'">{{ rule.index }}:{{ rule.value }}</span>
                <span class="badge badge-primary rule-box" *ngIf="rule.type === 'questionnaire'">{{ rule.index }}:{{ rule.value }}</span>

                <div class="input-group" *ngIf="rule.type === 'product'">
                  <div class="input-group-prepend rule-box">
                    <span class="input-group-text"> {{ rule.index }}: </span>
                  </div>
                  <span class="form-control rule-box">
                    <span class="badge badge-primary"> {{ rule.value.name }} - {{ rule.value.dosagePills }}p * {{ rule.value.quantity }}q </span>
                  </span>
                </div>

                <div class="input-group" *ngIf="rule.type === 'factor'">
                  <div class="input-group-prepend rule-box">
                    <span class="input-group-text"> {{ rule.operatorSymbol }} {{ rule.operatorValue }} {{ rule.index }}: </span>
                  </div>
                  <span class="form-control rule-box">
                    <span *ngFor="let pair of rule.value" class="badge badge-primary"> {{ pair.name }}:{{ pair.value }} </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-2">
            <div class="input-group">
              <a class="btn btn-info btn-xs" [href]="step.editUrl" title="Edit in admin" target="_top"><em class="icon-pencil-square-o"></em></a>
              <a class="btn btn-primary btn-xs" (click)="navigateToStep(i)" title="Goto step" target="_top"><em class="icon-chain"></em></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
