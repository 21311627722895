import { Injectable } from '@angular/core';

@Injectable()
export class ConsoleService {
  public className = '';

  public toggleConsole() {
    if (this.className === 'show') {
      this.className = '';
    } else {
      this.className = 'show';
    }
  }
}
