 

<div *ngIf="activeStep && activeStep.question" [ngClass]="{ hidden: notReady || curtainOn }" class="jumbotron" #coverSlide>
  <img [src]="activeStep.question.image1_url" *ngIf="activeStep.question.image1_url" [alt]="activeStep.question.text1" class="cover cover-image" />
  <div class="cover  {{ activeStep.question.type?.controlName }}" [appAdaptHeight]="100">
    <div class="title1 centered" *ngIf="activeStep.question.text1" [innerHTML]="activeStep.question.text1"></div>
    <div class="title2 centered" *ngIf="activeStep.question.text2" [innerHTML]="activeStep.question.text2"></div>
    <div class="title3 centered" *ngIf="activeStep.question.text3" [innerHTML]="activeStep.question.text3"></div>
    <app-question-properties
      *ngIf="activeStep && activeStep.question"
      (valid)="updateStepValidity($event)"
      [rules]="activeStep.rules"
      [question]="activeStep.question"
      [type]="activeStep.question.type"
      (paging)="movePaging($event)"
    >
    </app-question-properties>
  </div>
</div>
