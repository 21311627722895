import { Injectable } from '@angular/core';
import { SessionKeys } from '../consts';
import { FactorsService } from './factors.service';
import { ProductsService } from './products.service';
import { SessionStorageService } from '@services/sessionStorage.service';

export interface TimelineStep {
  index: number;
  skipOnBack: boolean;
  ops: Ops;
  target?: any;
  to?: number;
}
export interface Ops extends Record<string, any[]> {
  products: [];
  factors: [];
}

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  constructor(
    private productsService: ProductsService,
    private factorsService: FactorsService,
    public sessionStorageService: SessionStorageService,
  ) {
    this.load();
  }

  public timeline: TimelineStep[] = [];

  public addStep(index: number, stepData: TimelineStep) {
    this.timeline[index] = stepData;
    this.currentIndex = index;
    this.syncTimeline();
    this.persist();
  }

  syncTimeline() {
    this.timeline = this.timeline.splice(0, this.currentIndex);
  }

  public mergeStep(index: number, stepData: { ops?: Ops; to: number; skipOnBack: boolean }) {
    if (stepData) {
      this.timeline[index] = this.timeline[index] || { index };
      mergeDeep(this.timeline[index], stepData);
      this.currentIndex = index;
      this.persist();
    }
  }

  public indexExist(index: number) {
    const tempStep = this.timeline.filter(step => {
      return step?.index !== undefined && Number(step.index) === Number(index);
    });

    return tempStep.length;
  }

  movePrev(activeStepIndex: number) {
    const finalBackStep = this.getNextBackStep(activeStepIndex);
    return Number(finalBackStep);
  }

  public getNextBackStep(lastIndex: number) {
    const tempStep = this.timeline.filter(step => {
      return step?.to && Number(step.to) === Number(lastIndex);
    });

    if (tempStep.length) {
      const deletedStep = this.timeline.pop();

      if (deletedStep?.ops) {
        if (deletedStep?.ops?.factors?.length) {
          deletedStep.ops.factors.forEach((factorPair: any) => {
            this.factorsService.removeFromFactor(factorPair.factor, tempStep[0].index);
            const factorToValidate = this.factorsService.getFactor(factorPair.factor);
            if (factorToValidate === factorPair.prev) {
              console.log('state is valid for factor', factorToValidate, factorPair.factor);
            }
          });
        }

        console.info('Log: deleted step', deletedStep);
        if (deletedStep.ops.products?.length) {
          this.productsService.reset();
        }
      }

      this.persist();
      return tempStep[0].index;
    } else {
      const stepOffirst = this.timeline
        .filter(step => step?.to)
        .sort((a: any, b: any) => {
          if (!a || !b) {
            return 1;
          }
          return Number(a.to) < Number(b.to) ? 1 : -1;
        })[0];

      if (!Number.isNaN(stepOffirst.to) && stepOffirst.to) {
        lastIndex = stepOffirst.to;
        this.timeline = this.timeline.splice(0, lastIndex);
        this.persist();
      }
    }
    this.currentIndex = lastIndex;
    return lastIndex;
  }

  currentIndex = 0;

  reset() {
    this.timeline = [];
  }

  persist() {
    this.sessionStorageService.setItem(SessionKeys.Timeline, this.timeline);
  }

  load() {
    const res = this.sessionStorageService.getItem<TimelineStep[]>(SessionKeys.Timeline) || [];
    if (res) {
      this.timeline = res;
    }
  }
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
