import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { TimelineService } from '@services/timeline/timeline.service';
import { NavigationService } from '@services/navigation.service';
import { ProService } from '@services/pro.service';
import { ProRouterService } from '@services/pro-router.service';
import { LoaderService } from '@services/loader.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-pro-router',
  templateUrl: './pro-router.component.html',
  styleUrls: ['./pro-router.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProRouterComponent extends PropertiesBaseComponent implements OnChanges, OnDestroy {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public pagingService: PagingService,
    public timelineService: TimelineService,
    public navigationService: NavigationService,
    public proService: ProService,
    public proRouterService: ProRouterService,
    public loaderService: LoaderService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    this.debugMode = this.route.snapshot.queryParams.debug;
    const subscription = this.onLoad.subscribe(async (observer): Promise<void> => {
      console.info('Log: router subscription', observer);

      subscription.unsubscribe();

      const proResolution = await this.proRouterService.resolveUserRouter();

      setTimeout(async () => {
        if (proResolution?.condition?.proTarget) {
          await this.navigationService.moveToPro(proResolution.condition.proTarget, 1);
        } else {
          this.navigationService.movePage(true);
          this.changeDetectorRef.detectChanges();
        }
      }, 1500);
    });

    this.subscriptions.add(
      this.route.params.subscribe(async (params): Promise<void> => {
        this.activeStepIndex = params.step_id;
      }),
    );
  }

  activeStepIndex = 0;

  public results: Record<string, any> = {};

  debugMode = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateProps(event: any) {
    // this.onUpdate.next({ questionId: this.question._id, result: this.stepValue });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
