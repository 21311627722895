<div *ngIf="question">
  <div class="form-group">
    <form>
      <div [ngSwitch]="_properties.inputType" class="center">
        <div *ngSwitchCase="'email'">
          <input
            name="inputData"
            (focus)="noScroll()"
            [(ngModel)]="EnteredValue"
            (keyup)="updateProps(true)"
            (mouseup)="updateProps(true)"
            [placeholder]="_properties.placeholder || ''"
            #inputData
            type="email"
            class="form-control form-control-lg form-{{ _properties.inputType }}"
            [email]="true"
          />

          <div *ngIf="notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Is this your email?</div>
        </div>
        <div *ngSwitchCase="'multiline'">
          <textarea
            name="inputData"
            [(ngModel)]="EnteredValue"
            (focus)="noScroll()"
            (keyup)="updateProps(true)"
            (mouseup)="updateProps(true)"
            [placeholder]="_properties.placeholder || ''"
            #inputData
            class="form-control form-control-lg"
          ></textarea>

          <div *ngIf="notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Tell us something?</div>
        </div>

        <div *ngSwitchCase="'date'" style="text-align: center; width: 100%">
          <div style="margin-right: auto; margin-left: auto; width: 150px">
            <app-date-picker name="inputData" (change)="updateProps(true)" [(ngModel)]="EnteredValue"></app-date-picker>
          </div>

          <div class="container flex-container">
            <div class="disclaimer-container" *ngIf="EnteredValue >= childhoodYear">
              <div class="title3" [innerHTML]="_properties.explain | sanitizeHtml"></div>

              <div class="disclaimer-text">
                <input id="optin" type="checkbox" [ngModelOptions]="{ standalone: true }" class="checkbox" [(ngModel)]="checkedOptIn" (change)="updateProps()" />
                <label for="optin" class="disclaimer-text inline" [innerHTML]="_properties.disclaimer | sanitizeHtml"> </label>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'values'">
          <div *ngIf="_properties.dataSets && _properties.dataSets.length > 1" style="display: flex; flex-direction: row; margin: 0 auto; width: 300px">
            <div *ngFor="let dataSet of _properties.dataSets">
              <app-select-range *ngIf="dataSet.active" name="inputData" [config]="dataSet" (change)="updateProps(true)" [(ngModel)]="EnteredValue"></app-select-range>
            </div>
            <div style="width: 150px">
              <button
                *ngFor="let dataSet of _properties.dataSets; let i = index"
                class="btn btn-default action-{{dataSet.units}}"
                type="button"
                (click)="toggleUnits(dataSet)"
                [ngClass]="{ active: dataSet.active, 'btn-left': i === 0, 'btn-right': i === _properties.dataSets.length - 1 }"
              >
                {{ dataSet.units }}
              </button>
            </div>
          </div>

          <div *ngIf="_properties.dataSets && _properties.dataSets.length === 1">
            <app-select-range name="inputData" [config]="_properties" (change)="updateProps(true)" [(ngModel)]="EnteredValue"></app-select-range>
            <div *ngIf="_properties.inputType === 'text' && notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Did you fill in the data?</div>
          </div>
        </div>

        <div *ngSwitchDefault class="center">
          <input
            name="inputData"
            [(ngModel)]="EnteredValue"
            [placeholder]="_properties.placeholder || ''"
            (keyup)="updateProps(true)"
            (mouseup)="updateProps(true)"
            (focus)="noScroll()"
            min="0"
            #inputData
            [type]="_properties.inputType"
            class="form-control form-control-lg form-{{ _properties.inputType }}"
          />

          <div *ngIf="_properties.inputType === 'text' && notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Did you fill in the data?</div>
        </div>
      </div>
      <div class="center">
        <button
          *ngIf="_properties.text1"
          id="actionButton"
          class="btn btn-primary btn-lg"
          style="width: 300px"
          (click)="moveNext()"
          [disabled]="!canContinue"
          [innerHTML]="_properties.text1"
        ></button>
      </div>
    </form>
  </div>
</div>
