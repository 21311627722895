import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { TokenService } from '@services/token.service';
import { SessionKeys } from '@services/consts';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ProRouterService } from '@services/pro-router.service';
import { ProService } from '@services/pro.service';
import { ConditionService, ConditionStateResult } from '@services/condition.service';
import { LoaderService } from '@services/loader.service';
import { environment } from '@environments/environment';
import { SessionStorageService } from '@services/sessionStorage.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control?.dirty || control?.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewsletterComponent extends PropertiesBaseComponent implements OnChanges, OnDestroy, AfterViewInit {
  navigated = false;
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    public tokenService: TokenService,
    public gtmService: GoogleTagManagerService,
    public proRouterService: ProRouterService,
    public proService: ProService,
    public conditionService: ConditionService,
    public loaderService: LoaderService,
    public sessionStorageService: SessionStorageService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    console.info('creating another newsletter instance');
    const singleSubscription = this.onLoad.subscribe(async () => {
      if (this.resultsService.emailResolved) {
        return;
      }
      this.loaderService.hideContent();
      singleSubscription.unsubscribe();
      let enteredValue = this.resultsService.getResultsForDimension('email');
      const userData = this.tokenService.decodeToken();

      console.info('Log: userData', userData);
      enteredValue = enteredValue || userData?.email;
      this.EnteredValue = enteredValue;

      const started = this.sessionStorageService.getItem(SessionKeys.Starter);
      console.info('Log: started value', started, enteredValue);
      const dir = +this.route.snapshot.queryParams.dir || 1;
      if (enteredValue && (dir > 0 || userData?.email)) {
        this.loaderService.hideContent();
        this.showNewsletter = false;
        console.info('started pro so we have the email address');

        this.loaderService.notReady();

        setTimeout(async () => {
          await this.moveNext();
        }, 2500);

        return;
      }

      if (this._properties.inputType === 'multiline') {
        this.notValid = false;
        this.canContinue = true;
      }

      if (enteredValue) {
        console.info(enteredValue, 'Please enter');
        await this.moveNext();
        return;
      }

      console.info('Log: got ready in news letter');
      this.loaderService.isReady();
      this.showNewsletter = true;
    });
    this.subscriptions.add(singleSubscription);
    const subscription = this.keyServiceRef.keyEventObs.subscribe(async keys => {
      switch (keys) {
        case 'k-Enter':
          if (!this.EnteredValue) {
            this.notValid = true;
          }

          if (await this.updateProps()) {
            this.resultsService.nextPage();
          }
          break;
      }
    });

    this.subscriptions.add(subscription);

    this.noScroll();
  }

  hideWhenLoading = true;

  notValid = false;

  showNewsletter = false;

  @ViewChild('inputData') public inputData?: ElementRef;

  public matcher = new MyErrorStateMatcher();

  public EnteredValue: any;

  public checkedOptIn = true;

  public ngAfterViewInit() {
    this.inputData?.nativeElement.focus();
    this.updateProps();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  scrollInterval: any;

  public noScroll() {
    window.scroll({ top: 0, left: 0 });
  }

  public isValid(text: any) {
    if (!text) {
      return false;
    }

    if (!this.checkedOptIn) {
      return false;
    }
    const regularExpression =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(text).toLowerCase());
  }

  @Output() public paging: EventEmitter<boolean> = new EventEmitter();

  OTPCode?: string;

  OTP = false;

  OTPError?: string;

  OTPRemoteValid?: boolean;

  OTPStepNext?: boolean;

  validateOTP(): boolean {
    return this.OTPCode?.length === 4;
  }

  validatePreOTP() {
    this.OTPStepNext = this.validateOTP();
  }

  async resendOTP() {
    const emailStartedOTP = await this.tokenService.startOTPVerification('0000', this.EnteredValue);
    if (emailStartedOTP.result) {
      // got to OTP screen
      this.OTP = true;
    }
  }

  async handleConditionsLoad() {
    if (!this.conditionService._conditions || !Object.values(this.conditionService._conditions)?.length) {
      const conditionsDic: any = {};

      this.proService.activePro?.steps.forEach(step => {
        step.conditions?.forEach(condition => {
          if (condition.conditionType === 'Rule') {
            conditionsDic[condition] = condition;
          }
        });
      });

      if (Object.values(conditionsDic).length) {
        const proResolution: Record<string, ConditionStateResult> = await this.proRouterService.resolveProConditions(conditionsDic);
        this.conditionService.setConditions(proResolution);
      }
    }
  }

  async moveNext() {
    // load conditions
    if (await this.updateProps()) {
      this.loaderService.notReady();
      // this.loaderService.hideContent();
      console.info('Log: Got email so we started');
      this.sessionStorageService.setItem(SessionKeys.Starter, 'started');

      this.sessionStorageService.setItem(SessionKeys.Navigated, 'navigated');
      if (this.question?.ga_event && environment.production) {
        this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
      }

      this.resultsService.emailResolved = true;

      if (!this._properties.activateOTP) {
        await this.handleConditionsLoad();
        this.resultsService.nextPage();
        return;
      }

      if (this.OTP && this.validateOTP()) {
        const emailStartedOTP = await this.tokenService.endOTPVerification('0000', this.OTPCode!, this.EnteredValue);
        if (emailStartedOTP.result) {
          // got to OTP screen
          this.OTPRemoteValid = true;
          setTimeout(() => {
            this.resultsService.nextPage();
          }, 2000);
        } else {
          this.OTPRemoteValid = false;
          this.OTPError = 'Pin do not match';
          this.OTPStepNext = false;
        }
      } else if (!this.OTP) {
        const emailStartedOTP = await this.tokenService.startOTPVerification('0000', this.EnteredValue);
        if (emailStartedOTP.result) {
          // got to OTP screen
          this.OTP = true;
        }
      }
    }
  }

  public async updateProps() {
    const validationResult = this.isValid(this.EnteredValue);
    console.info('Log: validationResult', validationResult);
    if (validationResult) {
      //  this.loaderService.hideContent();
      this.notValid = false;
      this.canContinue = true;

      // start otp

      this.onUpdate.next({
        questionId: this.question?._id,
        result: this.EnteredValue.toLowerCase(),
        userExistsTarget: this._properties.userExistsTarget,
      });

      return true;
    } else {
      this.canContinue = false;
      this.onUpdate.next({
        errors: this.EnteredValue,
      });
      return false;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
