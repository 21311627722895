import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleService } from '@services/console.service';
import { ResultsService } from '@services/results.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { ConditionService } from '@services/condition.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@services/sessionStorage.service';

@Component({
  selector: 'app-pro-results',
  templateUrl: './pro-results.component.html',
  styleUrls: ['./pro-results.component.scss'],
})
export class ProResultsComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public resultsService: ResultsService,
    public router: Router,
    public consoleService: ConsoleService,
    public sanitizer: DomSanitizer,
    public conditionsService: ConditionService,
    public sessionStorageService: SessionStorageService,
  ) {}

  public subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @ViewChild('scrollcontainer') containerRef?: ElementRef;

  public results: Record<string, any> = {};

  @Input('proId') proId = '';

  public className = '';

  _steps: any;

  @Input('steps') set steps(value: any) {
    value = value.map((step: any) => {
      step.editUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.adminUrl}/dashboard/questions/pro-list/${step.proId}/edit-question/${step.questionId}/`);
      return step;
    });
    this._steps = value;
  }

  get steps() {
    return this._steps;
  }

  @Input('editUrl') public editUrl: SafeResourceUrl = '';

  @Input('activeStep') public activeStep?: any;

  consolePos: { top: string; left: string } = { top: '200px', left: '100px' };

  ngAfterViewInit() {
    if (this.containerRef) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.containerRef.nativeElement.onscroll = () => {
        if (this.containerRef) {
          const { scrollTop } = this.containerRef.nativeElement;
          this.sessionStorageService.setItem('console-scroll', scrollTop);
        }
      };
      const topScroll = this.sessionStorageService.getItem('console-scroll');
      if (topScroll) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.containerRef.nativeElement.scrollTop = topScroll;
      }
    }
  }

  conditionsDic: Record<string, { name: string; conditionType: string; resolution?: boolean }> = {};

  conditionsResults: any;

  public ngOnInit(): void {
    this.subscriptions.add(
      this.conditionsService.conditions$.subscribe((dic: any) => {
        this.conditionsDic = dic;
      }),
    );
    this.subscriptions.add(
      this.conditionsService.conditionsResolved$.subscribe((dic: any) => {
        this.conditionsResults = dic;
      }),
    );

    this.results = this.resultsService.getResults();
  }

  public navigateToStep(index: number) {
    return this.router.navigate([`/pro/${this.proId}/step/${index}`], { queryParams: { dir: 1, enter: 1, debug: 1 } });
  }

  public persistConsolePosition(data: any) {
    const { x, y } = data.source.element.nativeElement.getBoundingClientRect();

    const dest = { top: `${y}px`, left: `${x}px` };

    this.sessionStorageService.setItem('console-position', dest);
  }

  public getResult(questionId: string | undefined): any {
    if (questionId) {
      if (typeof this.results[questionId] === 'object') {
        let objectForStringify = this.results[questionId];
        if (Array.isArray(this.results[questionId])) {
          objectForStringify = this.results[questionId].map((item: any) => item.label);
        }
        return JSON.stringify(objectForStringify, null, 4);
      } else {
        return this.results[questionId];
      }
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResizeEnd(event: any) {
    return true;
  }
}
