import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagingService {
  public configureEvent: BehaviorSubject<any> = new BehaviorSubject<any>({ next: null, prev: null });

  public configure(configuration: { next: boolean; prev: boolean }) {
    console.info('Log: configure event', configuration);
    this.configureEvent.next(configuration);
  }
}
