<div *ngIf="question && debugMode">
  <div class="row">
    <div class="col-8">
      <button (click)="dryRun()" class="btn btn-sm" [ngClass]="{ 'btn-success': dryRunResult, 'btn-warning': dryRunResult === false }">Test all rules</button>

      {{ loadedConditions | json }}
      
      <table class="table table-hover">
        <thead class="thead-dark">
          <tr>
            <th>Initial</th>
            <th>Current</th>
            <th>Override</th>
          </tr>
        </thead>
        <tbody class="pad" *ngFor="let rule of rules; let i = index">
          <tr>
            <td>
              <button (click)="dryRun(rule)" class="btn btn-sm" [ngClass]="{ 'btn-success': rule.validationResult, 'btn-warning': rule.validationResult === false }">
                {{ rule.initial?.name }} Test rule
              </button>
            </td>
            <td>
              <button (click)="dryRun(rule)" class="btn btn-sm" [ngClass]="{ 'btn-success': rule.validationResult, 'btn-warning': rule.validationResult === false }">
                {{ rule.target?.name }} Test rule
              </button>
            </td>
            <td>
              <button (click)="dryRun(rule)" class="btn btn-sm" [ngClass]="{ 'btn-success': rule.validationResult, 'btn-warning': rule.validationResult === false }">
                {{ rule.override?.name }} Test rule
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="center">
    <button id="actionButton" class="btn btn-primary btn-lg" (click)="moveOn()">Continue</button>
  </div>
</div>
