import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ConditionService } from './condition.service';

export interface NavigationRequestModel {
  isNext?: boolean;
  stepIndex?: number | boolean;
  skipRules?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    readonly router: Router,
    readonly conditionService: ConditionService,
  ) {}

  public navigationRequest$: Subject<NavigationRequestModel> = new Subject<NavigationRequestModel>();

  public movePage(isNext: boolean, skipRules: boolean = false) {
    console.info('Log: movePage', isNext);
    this.navigationRequest$.next({ isNext, skipRules });
  }

  public moveToStep(stepIndex: number) {
    console.info('Log: moveToStep', stepIndex);
    this.navigationRequest$.next({ stepIndex });
  }

  public moveToPro(proId: string, index: number = 0) {
    console.info('Log:', 'moveToPro', proId, index);
    const gotoUrl = `/pro/${proId}/step/${index}?dir=1&enter=1`;
    this.conditionService.conditionsChecked = false;

    return this.router.navigateByUrl(gotoUrl);
  }
}
