import { EventEmitter, Injectable } from '@angular/core';
import { SessionKeys } from '../consts';
import { SessionStorageService } from '@services/sessionStorage.service';

@Injectable({
  providedIn: 'root',
})
export class FactorsService {
  emitter: EventEmitter<any> = new EventEmitter<any>();

  private _factorsMap: Record<string, Record<number | string, number>> = {};
  /**
   *
   */
  constructor(public sessionStorageService: SessionStorageService) {}
  public reset() {
    this.sessionStorageService.removeItem(SessionKeys.Factors);
    this._factorsMap = {};
  }

  private persist() {
    this.sessionStorageService.setItem(SessionKeys.Factors, this._factorsMap);
  }

  public load() {
    this._factorsMap = this.sessionStorageService.getItem<any>(SessionKeys.Factors) || {};
  }

  removeFromFactor(factorName: string, id: string | number) {
    delete this._factorsMap[factorName][`_${id}`];
    this.persist();
  }

  addToFactor(factorName: string, id: number | string, delta: number) {
    this._factorsMap[factorName] = this._factorsMap[factorName] || {};
    this._factorsMap[factorName][`_${id}`] = delta;
    this.persist();
  }

  getFactor(factorName: string) {
    let total = 0;
    if (this._factorsMap[factorName]) {
      Object.values(this._factorsMap[factorName]).forEach((idKey: string | number) => {
        total += this._factorsMap[factorName][`${idKey}`];
      });
    }
    return total;
  }

  getFactors() {
    const factorMap: Record<string, number> = {};
    Object.keys(this._factorsMap).forEach(key => {
      Object.keys(this._factorsMap[key]).forEach((idKey: number | string) => {
        factorMap[key] = factorMap[key] || 0;
        factorMap[key] += this._factorsMap[key][`${idKey}`];
      });
    });

    return factorMap;
  }

  updateFactor(factorName: string, id: number, value: number) {
    this._factorsMap[factorName][id] = value;
    this.persist();
  }
}
