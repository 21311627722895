import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Pro, ProStep, Rule, RuleOperators } from '@models/pro.model';
import { SessionKeys } from './consts';
import { TimelineService, TimelineStep } from './timeline/timeline.service';
import { FactorsService } from './timeline/factors.service';
import { ProService } from './pro.service';
import { ProLocalResultService } from '@services/pro-local-result.service';
import { ProductsService } from './timeline/products.service';
import { TrackingService } from './tracking.service';
import { Subject } from 'rxjs';
import { SessionStorageService } from './sessionStorage.service';

export interface ResultMaterial {
  result: { questionId: string; result: string; time: Date; exceptions: any[] };
  pro: Pro;
  step: ProStep;
}

enum OpsType {
  products = 'products',
  factors = 'factors',
}
@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  constructor(
    private proService: ProService,
    private proLocalResultService: ProLocalResultService,
    private timelineService: TimelineService,
    private productsService: ProductsService,
    private factorsService: FactorsService,
    private trackingService: TrackingService,
    public sessionStorageService: SessionStorageService,
  ) {}

  emailResolved = false;

  private _results: Record<string, any> = {};

  private _dimensionResults: Record<string, any> = {};

  public dimensionQuestion: Record<string, string> = {};

  private elasticRecordId: string | null = '';

  public isValid: Subject<boolean> = new Subject<boolean>();

  public activeData: any;

  public nextPage$: Subject<boolean> = new Subject<boolean>();
  public nextPage() {
    this.nextPage$.next(true);
  }

  public processRulesGetResults(rules: Rule[], value: any): any {
    if (rules && value) {
      rules.forEach((rule: Rule) => {
        rule.applicable = false;
      });
      for (const rule of rules) {
        rule.applicable = false;
        if (this.testExpression(rule, value.result)) {
          rule.applicable = true;

          return true;
        }

        if (Array.isArray(rule)) {
          for (const indexRule of rule) {
            indexRule.applicable = false;
            if (indexRule.factors) {
              if (this.testExpression(indexRule, value.result)) {
                indexRule.applicable = true;
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  private _addToOps(type: OpsType, targetAndOperations: TimelineStep, data: any) {
    targetAndOperations.ops && (targetAndOperations.ops[type] as any[]).push(data);
  }

  public resetProducts() {
    const targetAndOperations = this.getTargetAndOperationsObject();
    targetAndOperations.ops = targetAndOperations.ops || ({} as any);
    targetAndOperations.ops[OpsType.products] = [];
    this.proService.canSkipValue = false;
    this.persist();
  }

  public addProducts(products: any[]) {
    const targetAndOperations = this.getTargetAndOperationsObject();
    products.forEach((product: any) => {
      console.info('Log: adding product', product);
      const productKey = this.buildProductKey(product);
      this.productsService.addToProducts(productKey, product);
      this._addToOps(OpsType.products, targetAndOperations, productKey);
    });
    this.persist();
    return targetAndOperations;
  }

  getTargetAndOperationsObject(): TimelineStep {
    return { target: null, ops: { products: [], factors: [] } } as TimelineStep;
  }

  buildProductKey(value: any) {
    if (value.quantity) {
      return `${value.sku}_${value.quantity}_${value.dosagePills}`;
    } else {
      return `${value.sku}_1_1`;
    }
  }

  setProductValue(targetAndOperations: any, value: any) {
    console.info('Log: ', 'setProductValue');
    const productKey = this.buildProductKey(value);
    this.productsService.addToProducts(productKey, value);
    this._addToOps(OpsType.products, targetAndOperations, productKey);
  }

  public processRules(rules: Rule[], value: any, currentStepIndex: number): any {
    let navigationTarget = null;
    const targetAndOperations = this.getTargetAndOperationsObject();
    if (rules && value) {
      for (const rule of rules) {
        if (rule.factors) {
          if (this.testExpression(rule, value.result)) {
            if (rule.values?.length) {
              rule.values.forEach((value: any) => {
                if (value) {
                  this.setProductValue(targetAndOperations, value);
                }
              });
            } else if (rule.value) {
              this.setProductValue(targetAndOperations, rule.value);
            }
            break;
          }
        } else {
          switch (rule.type) {
            case 'questionnaire':
              if (Array.isArray(value.result)) {
                if (rule.index === value.result[0].index) {
                  // find pro step
                  navigationTarget = this.proService.getFirstStepOfPro(rule.value, currentStepIndex);
                }
              } else if (rule.index === value.index) {
                navigationTarget = this.proService.getFirstStepOfPro(rule.value, currentStepIndex);
              }
              break;
            case 'factor':
              if (rule.index !== undefined && value.index !== undefined) {
                if (value.index !== undefined && rule.index === value.index) {
                  if (rule.value?.length) {
                    rule.value.forEach((pair: { name: string; value: any }) => {
                      const prevFactorValue = this.factorsService.getFactor(pair.name);
                      this._addToOps(OpsType.factors, targetAndOperations, { factor: pair.name, prev: Number(prevFactorValue), delta: Number(pair.value) });
                      this.factorsService.addToFactor(pair.name, currentStepIndex, Number(pair.value));
                      // this._factorsMap[pair.name] = this.factorsService.getFactor(pair.name);
                    });
                  }
                }
              } else {
                if (Array.isArray(value.result)) {
                  value.result.forEach((singleBox: any) => {
                    if (singleBox.index === rule.index) {
                      rule.value.forEach((pair: any) => {
                        if (pair.value === 'value') {
                          pair.value = value.result;
                        }
                        const prevFactorValue = this.factorsService.getFactor(pair.name);
                        this._addToOps(OpsType.factors, targetAndOperations, { factor: pair.name, prev: Number(prevFactorValue), delta: Number(pair.value) });
                        this.factorsService.addToFactor(pair.name, `${currentStepIndex}_${singleBox.index}`, Number(pair.value));
                      });
                    }
                  });
                } else {
                  if (this.testExpression(rule, value.result)) {
                    if (rule.value?.length) {
                      rule.value.forEach((pair: any) => {
                        if (pair.value === 'value') {
                          pair.value = value.result;
                        }

                        const prevFactorValue = this.factorsService.getFactor(pair.name);
                        this._addToOps(OpsType.factors, targetAndOperations, { factor: pair.name, prev: Number(prevFactorValue), delta: Number(pair.value) });

                        this.factorsService.addToFactor(pair.name, currentStepIndex, Number(pair.value));
                      });
                    }
                  }
                }
              }

              break;

            case 'question':
              if (rule.index === value.index) {
                navigationTarget = this.proService.getByQuestionId(rule.value, currentStepIndex);
                console.info('Log: rule is of question step', rule.index, navigationTarget);
              }
              break;
            case 'product':
              if (value.index !== undefined && rule.index === value.index) {
                if (rule.values?.length) {
                  rule.values.forEach((rule: any) => {
                    this.setProductValue(targetAndOperations, rule.value);
                  });
                } else if (rule.value) {
                  this.setProductValue(targetAndOperations, rule.value);
                }
              } else if (this.testExpression(rule, value.result)) {
                if (rule.values?.length) {
                  rule.values.forEach((rule: any) => {
                    this.setProductValue(targetAndOperations, rule.value);
                  });
                } else if (rule.value) {
                  this.setProductValue(targetAndOperations, rule.value);
                }
              }

              break;
          }
        }

        if (navigationTarget) {
          break;
        }
      }

      this.persist();
      if (navigationTarget) {
        targetAndOperations.target = navigationTarget;
      }
    }
    return targetAndOperations;
  }

  public testExpression(rule: Rule, result: any, isSet = false) {
    let flag = true;

    if (isSet) {
      //  for (let factor in Object.keys(rule.factors) {
      //    flag &&= this.testSubExpression(rule.factors[factor] as unknown as Rule, result[factor]);
      //  }
      return flag;
    } else {
      if (rule.factors) {
        for (const factor in rule.factors) {
          flag &&= this.testSubExpression(rule.factors[factor] as unknown as Rule, result[factor]);
        }
        return flag;
      } else {
        return this.testSubExpression(rule, result);
      }
    }
  }

  public testSubExpression(rule: Rule, result: any) {
    const checkValue = result || 0;
    if (!rule.operatorSymbol) {
      rule.operatorSymbol = 'eq';
    }
    if (!rule.operatorValue) {
      rule.operatorValue = '0';
    }

    switch (rule.operatorSymbol) {
      case RuleOperators.bt:
        return Number(checkValue) >= Number(rule.operatorValue) && Number(checkValue) <= Number(rule.operatorValue2);
      case RuleOperators.bte:
        return Number(checkValue) >= Number(rule.operatorValue) && Number(checkValue) <= Number(rule.operatorValue2);
      case RuleOperators.gt:
        return Number(checkValue) > Number(rule.operatorValue);
      case RuleOperators.lt:
        return Number(checkValue) < Number(rule.operatorValue);
      case RuleOperators.gte:
        return Number(checkValue) >= Number(rule.operatorValue);
      case RuleOperators.lte:
        return Number(checkValue) <= Number(rule.operatorValue);

      case RuleOperators.eq:
        return Number(checkValue) === Number(rule.operatorValue);
    }
    return false;
  }

  mongoRecordId?: string | null;

  public async handlePrivateResult(action: ResultMaterial, stepIndex: string) {
    this.mongoRecordId = this.sessionStorageService.getItem(SessionKeys.Mongo);
    if (action?.result?.questionId) {
      this._results[`${stepIndex.split(':')[0]}_${action.result.questionId}`] = action.result.result;
      this._dimensionResults[`${action.step.question?.dimension}`] = action.result.result;

      const proId = this.proService.activePro?._id;
      if (proId) {
        if (!this._results.token) {
          this._results.token = uuidv4();
        }
        const resultPayload = {
          token: this._results.token,
          [action.result.questionId]: action.result.result,
        };

        if (!this.mongoRecordId) {
          const resultWithToken = await this.proLocalResultService.setResultData(proId, resultPayload);
          this.mongoRecordId = resultWithToken.mongoId;
          if (this.mongoRecordId) {
            this.sessionStorageService.setItem(SessionKeys.Mongo, this.mongoRecordId);
          }
        } else {
          await this.proLocalResultService.updateResultData(proId, this.mongoRecordId, resultPayload);
        }
        await this.updateProStatus(proId, stepIndex);
      }
    }
    this.persist();
  }

  public async handleResult(action: ResultMaterial, stepIndex: string) {
    this.elasticRecordId = this.sessionStorageService.getItem(SessionKeys.Elastic);
    if (action?.result?.questionId) {
      this._results[`${stepIndex.split(':')[0]}_${action.result.questionId}`] = action.result.result;
      this._dimensionResults[`${action.step.question?.dimension}`] = action.result.result;

      const proId = this.proService.activePro?._id;
      if (proId) {
        if (!this._results.token) {
          this._results.token = uuidv4();
        }
        let resultPayload = {
          token: this._results.token,
          exceptions: action.result.exceptions,
          [action.result.questionId]: action.result.result,
        };

        console.info('Log: resultPayload', resultPayload);

        if (!this.elasticRecordId) {
          if (!this._results.token) {
            this._results.token = uuidv4();
          }
          resultPayload = {
            token: this._results.token,
            exceptions: action.result.exceptions,
            [action.result.questionId]: action.result.result,
          };

          const putResult: any = await this.proService.setResultData(proId, resultPayload);
          this.elasticRecordId = putResult.elasticId;
          if (this.elasticRecordId) {
            this.sessionStorageService.setItem(SessionKeys.Elastic, this.elasticRecordId);
          }
        } else {
          await this.proService.updateResultData(proId, this.elasticRecordId, resultPayload);
        }

        await Promise.all([this.updateProStatus(proId, stepIndex)]);
      }
    }

    this.persist();
  }

  async updateProTrend(proId: string, stepIndex: number) {
    try {
      const stepData = this.proService.getStep(stepIndex);
      this.elasticRecordId = this.sessionStorageService.getItem(SessionKeys.Elastic);
      if (this.elasticRecordId && stepData?.questionId) {
        this.trackingService.trackEnd(stepData, stepIndex);
        await this.trackingService.updateProTrend(proId, this.elasticRecordId, stepData.questionId, stepIndex);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateProStatus(proId: string, stepPosition: string) {
    try {
      this.mongoRecordId = this.sessionStorageService.getItem(SessionKeys.Mongo);
      if (this.mongoRecordId) {
        await this.proLocalResultService.updateProStatus(proId, this.mongoRecordId, { progress: stepPosition });
      }
    } catch (error) {
      console.error(error);
    }
  }

  public getResults() {
    return this._results;
  }

  public getFactors() {
    return this.factorsService.getFactors();
  }

  public getProducts() {
    return this.productsService.getProducts();
  }

  public getResultsForDimension(name: string) {
    console.info('Log: getting results from dimension ', this._dimensionResults, name);
    if (this._dimensionResults) {
      return this._dimensionResults[name];
    }
  }

  public setResultsForDimension(name: string, value: any) {
    this._dimensionResults[name] = value;
    this.persist();
  }

  public getResultsForQuestion(questionId: string) {
    if (this._results) {
      const activeIndex = this.timelineService.currentIndex + 1;
      return this._results[`${activeIndex}_${questionId}`];
    }
  }

  public reset() {
    this.sessionStorageService.clear();
    this.timelineService.reset();
    this.productsService.reset();
    this.factorsService.reset();

    this._results = {};
    this.dimensionQuestion = {};
    this._dimensionResults = {};
  }

  setToken(token: string) {
    this._results.token = token;
  }

  private persist() {
    console.info('Log: called persist');
    this.sessionStorageService.setItem(SessionKeys.Results, this._results || {});
    this.sessionStorageService.setItem(SessionKeys.DimensionResults, this._dimensionResults);
  }

  public async resultHandler(update: { isPrivate: boolean; payload: any; status: any }) {
    if (update.isPrivate) {
      return this.handlePrivateResult(update.payload, update.status).catch((error: Error) => {
        console.error(error);
      });
    } else {
      return this.handleResult(update.payload, update.status).catch((error: Error) => {
        console.error(error);
      });
    }
  }

  async load(useSessionData = true) {
    console.info('Log: results service load');
    if (useSessionData) {
      this._results = this.sessionStorageService.getItem<any>(SessionKeys.Results) || {};

      this.loadDimensions();
    }
    console.info('Log: results service', this._results);

    this.factorsService.load();
    return this.productsService.load();
  }

  loadDimensions() {
    this._dimensionResults = this.sessionStorageService.getItem<any>(SessionKeys.DimensionResults) || {};

    console.info('Log: dimensions results', this._dimensionResults);
  }
}
