<div [ngSwitch]="type?.controlName">
  <app-text-continue *ngSwitchCase="'app-text-continue'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-text-continue>
  <!-- (paging)="moveNext()" -->

  <app-select-one *ngSwitchCase="'app-select-one'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-select-one>

  <app-newsletter *ngSwitchCase="'app-newsletter'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"></app-newsletter>

  <app-value-slider *ngSwitchCase="'app-value-slider'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-value-slider>

  <app-select-many *ngSwitchCase="'app-select-many'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-select-many>

  <app-select-meds *ngSwitchCase="'app-select-meds'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-select-meds>

  <app-enter-value *ngSwitchCase="'app-enter-value'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-enter-value>

  <app-text-conditional *ngSwitchCase="'app-text-conditional'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question" (paging)="moveNext()">
  </app-text-conditional>

  <app-factor-calc *ngSwitchCase="'app-factor-calc'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-factor-calc>

  <app-bmi-calc *ngSwitchCase="'app-bmi-calc'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-bmi-calc>

  <app-age-relate *ngSwitchCase="'app-age-relate'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-age-relate>

  <app-treatment-checkup *ngSwitchCase="'app-treatment-checkup'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"></app-treatment-checkup>

  <app-product-calc *ngSwitchCase="'app-product-calc'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-product-calc>

  <app-pro-router *ngSwitchCase="'app-pro-router'" (onUpdate)="updateProps($event)" [rules]="rules" [question]="_question"> </app-pro-router>
</div>
