import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { environment } from '@environments/environment';
import { Question } from '@models/question.model';
import { DirtyService } from '@services/dirty.service';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { LoaderService } from '@services/loader.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-text-continue',
  templateUrl: './text-continue.component.html',
  styleUrls: ['./text-continue.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TextContinueComponent extends PropertiesBaseComponent implements OnDestroy {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    public sanitizer: DomSanitizer,
    public gtmService: GoogleTagManagerService,
    public loaderService: LoaderService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      switch (keys) {
        case 'k-Enter':
          this.resultsService.nextPage();
          break;
      }
    });

    this.pagingService.configure({ next: true, prev: true });
    this.subscriptions.add(subscription);
    this.loaderService.isReady();
  }

  public sanitizedProperties: any = {};

  protected override loaded(question: Question) {
    this.sanitizedProperties.text1 = this.sanitizer.bypassSecurityTrustHtml(question?.properties.text1);
    this.sanitizedProperties.text2 = this.sanitizer.bypassSecurityTrustHtml(question?.properties.text2);
  }

  @Output() public onUpdate: EventEmitter<Record<string, any>> = new EventEmitter();

  @Output() public paging: EventEmitter<boolean> = new EventEmitter();

  public updateProps() {
    this.onUpdate.next({
      questionId: this.question?._id,
      result: true,
    });
  }

  moveNext() {
    if (this.question?.ga_event && environment.production) {
      this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
    }
    this.updateProps();
    this.resultsService.nextPage();
    this.paging.emit(true);

    if (this.question?.properties?.url) {
      setTimeout(() => {
        document.location.href = this.question?.properties.url;
      }, 2500);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
