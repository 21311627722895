import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@services/config.service';

export async function guardFn(appConfig: AppConfigService, router: Router): Promise<boolean> {
  if (!appConfig.getConfig().enabled) {
    await router.navigate(['/down-time']);
    return false;
  }
  return true;
}

@Injectable()
export class UnderConstructionGuard {
  constructor(public appConfig: AppConfigService, public router: Router) {}

  canActivate(): Promise<boolean> {
    return guardFn(this.appConfig, this.router);
  }
}
