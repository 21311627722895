<link href="https://fonts.gstatic.com" crossorigin rel="preconnect" />
<style>
  img.wp-smiley,
  img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
  }
</style>
<link rel="stylesheet" id="wp-block-library-css" href="https://c0.wp.com/c/6.2/wp-includes/css/dist/block-library/style.min.css" media="all" />
<style id="wp-block-library-inline-css">
  .has-text-align-justify {
    text-align: justify;
  }
</style>
<style id="safe-svg-svg-icon-style-inline-css">
  .safe-svg-cover .safe-svg-inside {
    display: inline-block;
    max-width: 100%;
  }
  .safe-svg-cover svg {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }
</style>
<link rel="stylesheet" id="mediaelement-css" href="https://c0.wp.com/c/6.2/wp-includes/js/mediaelement/mediaelementplayer-legacy.min.css" media="all" />
<link rel="stylesheet" id="wp-mediaelement-css" href="https://c0.wp.com/c/6.2/wp-includes/js/mediaelement/wp-mediaelement.min.css" media="all" />
<link rel="stylesheet" id="wc-blocks-vendors-style-css" href="https://c0.wp.com/p/woocommerce/7.5.1/packages/woocommerce-blocks/build/wc-blocks-vendors-style.css" media="all" />
<link rel="stylesheet" id="wc-blocks-style-css" href="https://c0.wp.com/p/woocommerce/7.5.1/packages/woocommerce-blocks/build/wc-blocks-style.css" media="all" />
<link rel="stylesheet" id="classic-theme-styles-css" href="https://c0.wp.com/c/6.2/wp-includes/css/classic-themes.min.css" media="all" />
<style id="global-styles-inline-css">
  body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
      135deg,
      rgb(74, 234, 220) 0%,
      rgb(151, 120, 209) 20%,
      rgb(207, 42, 186) 40%,
      rgb(238, 44, 130) 60%,
      rgb(251, 105, 98) 80%,
      rgb(254, 248, 76) 100%
    );
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
    --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
    --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
    --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
    --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
    --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
    --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
    --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
  }
  :where(.is-layout-flex) {
    gap: 0.5em;
  }
  body .is-layout-flow > .alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
  }
  body .is-layout-flow > .alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
  }
  body .is-layout-flow > .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body .is-layout-constrained > .alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
  }
  body .is-layout-constrained > .alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
  }
  body .is-layout-constrained > .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: var(--wp--style--global--content-size);
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body .is-layout-constrained > .alignwide {
    max-width: var(--wp--style--global--wide-size);
  }
  body .is-layout-flex {
    display: flex;
  }
  body .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
  }
  body .is-layout-flex > * {
    margin: 0;
  }
  :where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
  }
  .has-black-color {
    color: var(--wp--preset--color--black) !important;
  }
  .has-cyan-bluish-gray-color {
    color: var(--wp--preset--color--cyan-bluish-gray) !important;
  }
  .has-white-color {
    color: var(--wp--preset--color--white) !important;
  }
  .has-pale-pink-color {
    color: var(--wp--preset--color--pale-pink) !important;
  }
  .has-vivid-red-color {
    color: var(--wp--preset--color--vivid-red) !important;
  }
  .has-luminous-vivid-orange-color {
    color: var(--wp--preset--color--luminous-vivid-orange) !important;
  }
  .has-luminous-vivid-amber-color {
    color: var(--wp--preset--color--luminous-vivid-amber) !important;
  }
  .has-light-green-cyan-color {
    color: var(--wp--preset--color--light-green-cyan) !important;
  }
  .has-vivid-green-cyan-color {
    color: var(--wp--preset--color--vivid-green-cyan) !important;
  }
  .has-pale-cyan-blue-color {
    color: var(--wp--preset--color--pale-cyan-blue) !important;
  }
  .has-vivid-cyan-blue-color {
    color: var(--wp--preset--color--vivid-cyan-blue) !important;
  }
  .has-vivid-purple-color {
    color: var(--wp--preset--color--vivid-purple) !important;
  }
  .has-black-background-color {
    background-color: var(--wp--preset--color--black) !important;
  }
  .has-cyan-bluish-gray-background-color {
    background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
  }
  .has-white-background-color {
    background-color: var(--wp--preset--color--white) !important;
  }
  .has-pale-pink-background-color {
    background-color: var(--wp--preset--color--pale-pink) !important;
  }
  .has-vivid-red-background-color {
    background-color: var(--wp--preset--color--vivid-red) !important;
  }
  .has-luminous-vivid-orange-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
  }
  .has-luminous-vivid-amber-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
  }
  .has-light-green-cyan-background-color {
    background-color: var(--wp--preset--color--light-green-cyan) !important;
  }
  .has-vivid-green-cyan-background-color {
    background-color: var(--wp--preset--color--vivid-green-cyan) !important;
  }
  .has-pale-cyan-blue-background-color {
    background-color: var(--wp--preset--color--pale-cyan-blue) !important;
  }
  .has-vivid-cyan-blue-background-color {
    background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
  }
  .has-vivid-purple-background-color {
    background-color: var(--wp--preset--color--vivid-purple) !important;
  }
  .has-black-border-color {
    border-color: var(--wp--preset--color--black) !important;
  }
  .has-cyan-bluish-gray-border-color {
    border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
  }
  .has-white-border-color {
    border-color: var(--wp--preset--color--white) !important;
  }
  .has-pale-pink-border-color {
    border-color: var(--wp--preset--color--pale-pink) !important;
  }
  .has-vivid-red-border-color {
    border-color: var(--wp--preset--color--vivid-red) !important;
  }
  .has-luminous-vivid-orange-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
  }
  .has-luminous-vivid-amber-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
  }
  .has-light-green-cyan-border-color {
    border-color: var(--wp--preset--color--light-green-cyan) !important;
  }
  .has-vivid-green-cyan-border-color {
    border-color: var(--wp--preset--color--vivid-green-cyan) !important;
  }
  .has-pale-cyan-blue-border-color {
    border-color: var(--wp--preset--color--pale-cyan-blue) !important;
  }
  .has-vivid-cyan-blue-border-color {
    border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
  }
  .has-vivid-purple-border-color {
    border-color: var(--wp--preset--color--vivid-purple) !important;
  }
  .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
  }
  .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
  }
  .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
  }
  .has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
  }
  .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
  }
  .has-cool-to-warm-spectrum-gradient-background {
    background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
  }
  .has-blush-light-purple-gradient-background {
    background: var(--wp--preset--gradient--blush-light-purple) !important;
  }
  .has-blush-bordeaux-gradient-background {
    background: var(--wp--preset--gradient--blush-bordeaux) !important;
  }
  .has-luminous-dusk-gradient-background {
    background: var(--wp--preset--gradient--luminous-dusk) !important;
  }
  .has-pale-ocean-gradient-background {
    background: var(--wp--preset--gradient--pale-ocean) !important;
  }
  .has-electric-grass-gradient-background {
    background: var(--wp--preset--gradient--electric-grass) !important;
  }
  .has-midnight-gradient-background {
    background: var(--wp--preset--gradient--midnight) !important;
  }
  .has-small-font-size {
    font-size: var(--wp--preset--font-size--small) !important;
  }
  .has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium) !important;
  }
  .has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
  }
  .has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large) !important;
  }
  .wp-block-navigation a:where(:not(.wp-element-button)) {
    color: inherit;
  }
  :where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
  }
  .wp-block-pullquote {
    font-size: 1.5em;
    line-height: 1.6;
  }
</style>
<link rel="stylesheet" id="contact-form-7-css" href="https://evinature.com/wp-content/plugins/contact-form-7/includes/css/styles.css?ver=5.7.5.1" media="all" />
<link
  rel="stylesheet"
  id="mwb-zndsk-public-style-css"
  href="https://evinature.com/wp-content/plugins/mwb-zendesk-woo-order-sync/assets/zndsk-public-ticket.css?ver=2.0.3"
  media="all"
/>
<link rel="stylesheet" id="dashicons-css" href="https://c0.wp.com/c/6.2/wp-includes/css/dashicons.min.css" media="all" />
<link rel="stylesheet" id="thickbox-css" href="https://c0.wp.com/c/6.2/wp-includes/js/thickbox/thickbox.css" media="all" />
<link rel="stylesheet" id="woocommerce-layout-css" href="https://c0.wp.com/p/woocommerce/7.5.1/assets/css/woocommerce-layout.css" media="all" />
<style id="woocommerce-layout-inline-css">
  .infinite-scroll .woocommerce-pagination {
    display: none;
  }
</style>
<link
  rel="stylesheet"
  id="woocommerce-smallscreen-css"
  href="https://c0.wp.com/p/woocommerce/7.5.1/assets/css/woocommerce-smallscreen.css"
  media="only screen and (max-width: 768px)"
/>
<link rel="stylesheet" id="woocommerce-general-css" href="https://c0.wp.com/p/woocommerce/7.5.1/assets/css/woocommerce.css" media="all" />
<style id="woocommerce-inline-inline-css">
  .woocommerce form .form-row .required {
    visibility: visible;
  }
</style>
<link
  rel="stylesheet"
  id="wp-user-profile-avatar-frontend-css"
  href="https://evinature.com/wp-content/plugins/wp-user-profile-avatar/assets/css/frontend.min.css?ver=6.2"
  media="all"
/>
<link rel="stylesheet" id="evinature-style-css" href="https://evinature.com/wp-content/themes/evinature/style.css?ver=1.0.0" media="all" />
<link rel="stylesheet" id="fontawesome-css" href="https://pro.fontawesome.com/releases/v6.0.0-beta3/css/all.css?ver=6.2" media="all" />
<link rel="stylesheet" id="bootstrap.min-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/bootstrap.min.css?ver=6.2" media="all" />
<link rel="stylesheet" id="jquery.fancybox.min-css" href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.3.5/jquery.fancybox.min.css?ver=6.2" media="all" />
<link rel="stylesheet" id="slick-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/slick.css?ver=6.2" media="all" />
<link rel="stylesheet" id="style-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/style.css?ver=6.2" media="all" />
<link rel="stylesheet" id="custom-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/custom.css?ver=6.2" media="all" />
<link rel="stylesheet" id="custom-2-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/custom-2.css?ver=6.2" media="all" />
<link rel="stylesheet" id="woostyle-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/woostyle.css?ver=6.2" media="all" />
<link rel="stylesheet" id="responsive-css" href="https://evinature.com/wp-content/themes/evinature/assets/css/responsive.css?ver=6.2" media="all" />
<link rel="stylesheet" id="addtoany-css" href="https://evinature.com/wp-content/plugins/add-to-any/addtoany.min.css?ver=1.16" media="all" />
<style id="addtoany-inline-css">
  .a2a_dd.addtoany_share_save.addtoany_share img {
    height: 14px;
    width: 14px;
  }
  .share-bx {
    display: flex;
  }
</style>
<style id="rocket-lazyload-inline-css">
  .rll-youtube-player {
    position: relative;
    padding-bottom: 56.23%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .rll-youtube-player:focus-within {
    outline: 2px solid currentColor;
    outline-offset: 5px;
  }
  .rll-youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: 0 0;
  }
  .rll-youtube-player img {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;
  }
  .rll-youtube-player img:hover {
    -webkit-filter: brightness(75%);
  }
  .rll-youtube-player .play {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: url(https://evinature.com/wp-content/plugins/wp-rocket/assets/img/youtube.png) no-repeat center;
    background-color: transparent !important;
    cursor: pointer;
    border: none;
  }
</style>
<link rel="stylesheet" id="jetpack_css-css" href="https://c0.wp.com/p/jetpack/12.0/css/jetpack.css" media="all" />
<script src="https://c0.wp.com/c/6.2/wp-includes/js/jquery/jquery.min.js" id="jquery-core-js"></script>
<script src="https://c0.wp.com/c/6.2/wp-includes/js/jquery/jquery-migrate.min.js" id="jquery-migrate-js"></script>
<script id="addtoany-core-js-before">
  window.a2a_config = window.a2a_config || {};
  a2a_config.callbacks = [];
  a2a_config.overlays = [];
  a2a_config.templates = {};
</script>
<script async src="https://static.addtoany.com/menu/page.js" id="addtoany-core-js"></script>
<script async src="https://evinature.com/wp-content/plugins/add-to-any/addtoany.min.js?ver=1.1" id="addtoany-jquery-js"></script>
<script src="https://evinature.com/wp-content/plugins/duracelltomi-google-tag-manager/js/gtm4wp-woocommerce-classic.js?ver=1.16.2" id="gtm4wp-woocommerce-classic-js"></script>
<script src="https://evinature.com/wp-content/plugins/duracelltomi-google-tag-manager/js/gtm4wp-woocommerce-enhanced.js?ver=1.16.2" id="gtm4wp-woocommerce-enhanced-js"></script>
<script defer src="https://stats.wp.com/s-202315.js" id="woocommerce-analytics-js"></script>
<script src="https://player.vimeo.com/api/player.js?ver=1" id="player-js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.3.5/jquery.fancybox.min.js?ver=1" id="jquery.fancybox.min-js"></script>
<script src="https://evinature.com/wp-content/themes/evinature/assets/js/slick.min.js?ver=1" id="slick-js"></script>
<script src="https://evinature.com/wp-content/themes/evinature/assets/js/bootstrap.bundle.min.js?ver=1" id="bootstrap.bundle.min-js"></script>
<script src="https://evinature.com/wp-content/themes/evinature/assets/js/custom.js?ver=1" id="custom-js"></script>
<link rel="https://api.w.org/" href="https://evinature.com/wp-json/" />
<link rel="EditURI" type="application/rsd+xml" title="RSD" href="https://evinature.com/xmlrpc.php?rsd" />
<link rel="wlwmanifest" type="application/wlwmanifest+xml" href="https://evinature.com/wp-includes/wlwmanifest.xml" />
<meta name="generator" content="WordPress 6.2" />
<meta name="generator" content="WooCommerce 7.5.1" />
<meta name="facebook-domain-verification" content="lyqqv5gzkjwg0rcmnzguesdjpxwukc" />
<meta name="trustpilot-one-time-domain-verification-id" content="b99155ba-8180-4823-b6ee-8d1671082975" />
<!-- HFCM by 99 Robots - Snippet # 4: cookie banner script -->
<script type="text/javascript" charset="UTF-8" src="//geo.cookie-script.com/s/d2055403d8cfb89a7ebe6fa5bd980438.js?region=eu"></script>
<!-- /end HFCM by 99 Robots -->
<!-- HFCM by 99 Robots - Snippet # 7: Image resize in Product Carousel -->
<style type="text/css">
  .product-template-default .smallbx-slide img {
    height: 100% !important;
  }
  .latest-community-box img {
    object-fit: cover;
    height: 275px !important;
  }
</style>
<!-- /end HFCM by 99 Robots -->
<!-- HFCM by 99 Robots - Snippet # 9: word break for mobile basket -->
<style>
  .supplements-dt h2 {
    word-break: normal !important;
  }
</style>
<!-- /end HFCM by 99 Robots -->
<script type="text/javascript">
  var gsfwc_ajaxurl = 'https://evinature.com/wp-admin/admin-ajax.php';
</script>
<style>
  img#wpstats {
    display: none;
  }
</style>

<!-- Google Tag Manager for WordPress by gtm4wp.com -->
<!-- GTM Container placement set to footer -->
<script data-cfasync="false" data-pagespeed-no-defer type="text/javascript">
  var dataLayer_content = { pagePostType: '404-error', cartContent: { totals: { applied_coupons: [], discount_total: 0, subtotal: 0, total: 0 }, items: [] } };
  dataLayer.push(dataLayer_content);
</script>
<script data-cfasync="false">
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = '//www.googletagmanager.com/gtm.' + 'js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-5XPVJ2H');
</script>
<!-- End Google Tag Manager -->
<!-- End Google Tag Manager for WordPress by gtm4wp.com -->
<noscript
  ><style>
    .woocommerce-product-gallery {
      opacity: 1 !important;
    }
  </style></noscript
>

<style type="text/css">
  /* If html does not have either class, do not show lazy loaded images. */
  html:not(.jetpack-lazy-images-js-enabled):not(.js) .jetpack-lazy-image {
    display: none;
  }
</style>

<style id="wp-custom-css">
  .fa-twitter {
    display: none;
  }
  .meet-founders img {
    border-radius: 16px !important;
    width: 100%;
    height: auto;
  }
  .single-product .input-text.qty.text {
    display: none;
  }

  .typography iframe {
    margin: 0 auto !important;
  }
  section.typography {
    padding-top: 50px;
  }
  .testimonail-data {
    margin: 0 auto;
  }

  .product-details-data u {
    text-decoration: underline;
  }

  .my-accout-sd p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    margin: 20px 0;
  }
  .my-accout-sd p a {
    color: #7cb261;
    text-decoration: underline;
  }
  .my-accout-sd p a:hover {
    color: #fb7f76;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .testimonail-data p {
      text-align: center;
    }
  }

  @media (min-width: 448px) and (max-width: 575px) {
    .testimonail-data p {
      text-align: center;
    }
  }

  @media (max-width: 447px) {
    .testimonail-data p {
      text-align: center;
    }
  }

  button.single_add_to_cart_button.btn-main {
    float: left;
    margin: 35px 40px 35px 0;
  }

  @media (min-width: 992px) and (max-width: 1024px) {
    button.single_add_to_cart_button.btn-main {
      float: left;
      margin: 35px 25px 35px 0;
    }
  }
</style>
<noscript
  ><style id="rocket-lazyload-nojs-css">
    .rll-youtube-player,
    [data-lazy-src] {
      display: none !important;
    }
  </style></noscript
>
<style id="wpforms-css-vars-root">
  :root {
    --wpforms-field-border-radius: 3px;
    --wpforms-field-background-color: #ffffff;
    --wpforms-field-border-color: rgba(0, 0, 0, 0.25);
    --wpforms-field-text-color: rgba(0, 0, 0, 0.7);
    --wpforms-label-color: rgba(0, 0, 0, 0.85);
    --wpforms-label-sublabel-color: rgba(0, 0, 0, 0.55);
    --wpforms-label-error-color: #d63637;
    --wpforms-button-border-radius: 3px;
    --wpforms-button-background-color: #066aab;
    --wpforms-button-text-color: #ffffff;
    --wpforms-field-size-input-height: 43px;
    --wpforms-field-size-input-spacing: 15px;
    --wpforms-field-size-font-size: 16px;
    --wpforms-field-size-line-height: 19px;
    --wpforms-field-size-padding-h: 14px;
    --wpforms-field-size-checkbox-size: 16px;
    --wpforms-field-size-sublabel-spacing: 5px;
    --wpforms-field-size-icon-size: 1;
    --wpforms-label-size-font-size: 16px;
    --wpforms-label-size-line-height: 19px;
    --wpforms-label-size-sublabel-font-size: 14px;
    --wpforms-label-size-sublabel-line-height: 17px;
    --wpforms-button-size-font-size: 17px;
    --wpforms-button-size-height: 41px;
    --wpforms-button-size-padding-h: 15px;
    --wpforms-button-size-margin-top: 10px;
  }
</style>

<div class="error404 wp-custom-logo theme-evinature woocommerce-no-js hfeed">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-dark-grayscale">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 0.49803921568627" />
          <feFuncG type="table" tableValues="0 0.49803921568627" />
          <feFuncB type="table" tableValues="0 0.49803921568627" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-grayscale">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 1" />
          <feFuncG type="table" tableValues="0 1" />
          <feFuncB type="table" tableValues="0 1" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-purple-yellow">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.54901960784314 0.98823529411765" />
          <feFuncG type="table" tableValues="0 1" />
          <feFuncB type="table" tableValues="0.71764705882353 0.25490196078431" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-blue-red">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 1" />
          <feFuncG type="table" tableValues="0 0.27843137254902" />
          <feFuncB type="table" tableValues="0.5921568627451 0.27843137254902" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-midnight">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0 0" />
          <feFuncG type="table" tableValues="0 0.64705882352941" />
          <feFuncB type="table" tableValues="0 1" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-magenta-yellow">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.78039215686275 1" />
          <feFuncG type="table" tableValues="0 0.94901960784314" />
          <feFuncB type="table" tableValues="0.35294117647059 0.47058823529412" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-purple-green">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.65098039215686 0.40392156862745" />
          <feFuncG type="table" tableValues="0 1" />
          <feFuncB type="table" tableValues="0.44705882352941 0.4" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs></svg
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width="0"
    height="0"
    focusable="false"
    role="none"
    style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden"
  >
    <defs>
      <filter id="wp-duotone-blue-orange">
        <feColorMatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.098039215686275 1" />
          <feFuncG type="table" tableValues="0 0.66274509803922" />
          <feFuncB type="table" tableValues="0.84705882352941 0.41960784313725" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <div class="main-wrapper-pg">
    <!-- Header -->

    <header class="" style="">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-md navbar-light">
          <a class="navbar-brand desltoplogo" href="https://evinature.com"><img src="https://storage.googleapis.com/stateless-evinature-dev/2022/01/logo-1.svg" alt="logo" /></a>
          <a class="navbar-brand mobile-logo" href="https://evinature.com"><img src="https://storage.googleapis.com/stateless-evinature-dev/2022/01/logo-1.svg" alt="logo" /></a>
          <div class="enter-head">
            <a href="https://evinature.com/my-account/"><img src="https://evinature.com/wp-content/themes/evinature/assets/images/user-ic.svg" alt="user" /></a>
            <a class="cartbtn" href="https://evinature.com/cart-page/"><i class="fal fa-shopping-cart"></i><span>0</span></a>
          </div>
          <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
            <span class="hamburger-toggle navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbar-content">
            <ul id="menu-main-menu" class="navbar-nav ml-auto">
              <li id="menu-item-2480" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-2480 nav-item"></li>
              <li id="menu-item-242" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-242 nav-item"></li>
              <li id="menu-item-243" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-243 nav-item"></li>
            </ul>
          </div>
        </nav>
      </div>
    </header>

    <main>
      <!-- End Header -->
      <section class="blog-artical-wrp error-page">
        <div class="container">
          <!-- <div class="row"> -->
          <h1>404</h1>
          <div class="error-content">
            <img src="https://evinature.com/wp-content/themes/evinature/assets/images/error-icon.svg" alt="error-icon" class="img-fluid" />
            <h2 class="page-title">We'll be back in a minute.</h2>
            <a [href]="proUrl" class="btn-main">Reload</a>
          </div>
          <!-- </div> -->
        </div>
        <!-- .page-header -->
      </section>
      <!-- .error-404 -->
    </main>
  </div>
</div>
