import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ProService } from '@services/pro.service';
import { environment } from '@environments/environment';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { NavigationService } from '@services/navigation.service';
import { LoaderService } from '@services/loader.service';
import { SessionKeys } from '@services/consts';
import { TokenService } from '@services/token.service';
import { LocalStorageService } from '@services/localStorage.service';
import { SessionStorageService } from '@services/sessionStorage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control?.dirty || control?.touched || isSubmitted));
  }
}
const BASEURL = `${environment.siteUrl}/${environment.cartPage}?`;
@Component({
  selector: 'app-product-calc',
  templateUrl: './product-calc.component.html',
  styleUrls: ['./product-calc.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProductCalcComponent extends PropertiesBaseComponent implements OnInit {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public pagingService: PagingService,
    public sanitizer: DomSanitizer,
    public proService: ProService,
    public gtmService: GoogleTagManagerService,
    private navigationService: NavigationService,
    private loaderService: LoaderService,
    private tokenService: TokenService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private modalService: BsModalService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);
    this.debugMode = this.route.snapshot.queryParams.debug;
  }

  modalRef?: BsModalRef;

  userId?: string;

  debugMode = false;

  public factorResults: any = [];

  public productResults: any[] = [];

  factors: any[] = [];

  public basketUrl = '';

  debugRules: any = [];

  public gotProducts?: boolean = undefined;

  gotMedsException = false;

  medsExceptions: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  @ViewChild('offerEmailFixDialog') modalTemplate?: TemplateRef<any>;

  activeOption: any;

  calculate(results: any) {
    for (let index = 0; index < this._rules.length; index++) {
      let rules: any = this._rules[index];

      if (rules.rules) {
        rules = rules.rules;
      }
      if (!Array.isArray(rules)) {
        rules = [rules];
      }
      const isValid = this.resultsService.processRulesGetResults(rules, {
        result: results,
      });
      if (isValid) {
        if (this._properties?.options?.[index]) {
          this.activeOption = this._properties.options[index];
          this.activeOption._label = this.sanitizer.bypassSecurityTrustHtml(this.activeOption.label.replace(/\n/g, '<br>'));
          if (this.activeOption.videoFile) {
            this.activeOption._video = this.sanitizer.bypassSecurityTrustHtml(`
            <iframe allow="autoplay; fullscreen;" frameborder="0" height="300"
            src="${this.activeOption.videoFile}?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
            width="100%"></iframe>
            `);
          }
        }
        break;
      }
    }
  }

  productsObject: Record<string, any> = {};

  public async ngOnInit() {
    this.debugRules = this._rules;
    this.factorResults = this.resultsService.getFactors();
    this.factors = Object.keys(this.factorResults);

    this.testEmailIssues();
    this.factors = [];
    for (const factor in this._properties.factors) {
      this.factors.push(factor);
    }
    this.proService.canSkipValue = false;
    this.calculate(this.factorResults);

    console.info('Log: calculate', this.factorResults);
    this.productResults = Object.values(this.resultsService.getProducts());

    console.info('Log: products', this.productResults);

    const values: any = await this.proService.getResultForExceptions();

    // unpack result
    const exceptionsDic: any = {};

    const allExceptionsForUser: any[] = values;

    console.info('Log: exceptions', allExceptionsForUser);

    const medsExceptionTemp: any = {};

    const userRef = localStorage.getItem(SessionKeys.ProRef);

    console.info('Log: the user ref', userRef);
    let calcByException = false;
    const alternativeProductsArray: any[] = [];

    if (!userRef) {
      try {
        allExceptionsForUser
          .filter(o => o.value)
          .forEach((o: any) => {
            medsExceptionTemp[o.value._id] = o;
          });
      } catch (error) {
        console.error(error);
      }

      this.medsExceptions = Object.values(medsExceptionTemp).filter((o: any) => !!o.value.negate !== true);

      console.info('Log: the meds exception', this.medsExceptions);

      if (this.medsExceptions) {
        for (const exception of this.medsExceptions) {
          if (!exceptionsDic[exception.value._id]) {
            try {
              if (exception.type === 'exception') {
                const fullException = await this.proService.getExceptionData(exception.value._id);

                exceptionsDic[fullException._id] = { ...exception.value, ...fullException };
              } else {
                exceptionsDic[exception.value._id] = exception;
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
      }

      this.productResults.forEach((result: Record<string, any>) => {
        if (exceptionsDic[result.exception]) {
          if (exceptionsDic[result.exception].alternativeProduct && exceptionsDic[result.exception].alternativeProduct !== 'None') {
            console.info('got alternative product');
            calcByException = true;
            const altProduct = exceptionsDic[result.exception].alternativeProductModel;
            console.info('altProduct', exceptionsDic[result.exception].alternativeProduct, altProduct);
            alternativeProductsArray.push(altProduct);
          }
          if (exceptionsDic[result.exception].alternativeTreatment && exceptionsDic[result.exception].alternativeTreatment !== 'None') {
            const treatmentProducts = exceptionsDic[result.exception].alternativeTreatmentModel.properties;

            alternativeProductsArray.push(...treatmentProducts.products.map((o: any) => o.value));
            calcByException = true;
          }
          console.info('debug', exceptionsDic);
          this.gotMedsException = true;
        }
      });
    }

    console.info('Log: calcByException', calcByException);
    if (!calcByException) {
      console.info('Log: build product list', this.productResults);
      this.productResults.forEach((result: Record<string, any>) => {
        this.gotProducts = true;
        const pKey = result.sku;
        this.productsObject[pKey] = { sku: pKey, ...result };
      });
    } else {
      console.info('Log: build alternative product list', alternativeProductsArray);
      alternativeProductsArray.forEach((result: Record<string, any>) => {
        this.gotProducts = true;
        const pKey = result.sku;
        this.productsObject[pKey] = { sku: pKey, ...result };
      });

      console.info('Log: alternativeProductsArray', this.productsObject);
    }

    if (!this.gotProducts) {
      this.gotProducts = false;
    }

    this.buildBasketUrl();
    this.pagingService.configure({ next: false, prev: true });
    this.loaderService.isReady();
  }

  buildBasketUrl() {
    const skus: any[] = [];
    const pills: any[] = [];
    const quantity: any[] = [];
    const shopifyCodes: { sku: string; q: number }[] = [];
    Object.values(this.productsObject).forEach(pr => {
      skus.push(pr.sku);
      pills.push(pr.dosagePills);
      quantity.push(pr.quantity);
      shopifyCodes.push({ sku: pr.shopifyId, q: pr.quantity });
    });

    let userBlock = '';
    let shopifyUserBlock;
    const userData = this.tokenService.decodeToken();

    try {
      userBlock = `uname=${userData.fullname}&email=${userData.email.trim()}&userid=${userData.userId}`;
      shopifyUserBlock = `name=${encodeURI(userData.fullname)}&checkout[email]=${encodeURI(userData.email.trim())}`;
    } catch (error) {
      console.error('token error');
    }

    const productLine = shopifyCodes.map(pair => `${pair.sku}:${pair.q || 1}`).join(',');

    this.basketUrl = `https://shop.evinature.com/cart/${productLine}?storefront=true&${shopifyUserBlock}`;

    console.info('Log: compose the basket url', this.basketUrl);
  }
  async handleResultAndRedirectToBasket() {
    this.updateProps();

    if (this._question?.ga_event && environment.production) {
      console.info('ga event', { event: `${this._question.ga_event}_clicked` });
      this.gtmService.pushTag({ event: `${this._question.ga_event}_clicked` });
    }

    try {
      // build klaviyo payload
      const klaviyoPayload = {
        AddedItemSKU: Object.values(this.productsObject)
          .map(o => o.boxSku)
          .join(','),
        AddedItemVariantID: Object.values(this.productsObject)
          .map(o => o.shopifyId)
          .join(','),
        AddedItemProductID: Object.keys(this.productsObject),
        ItemCount: Object.keys(this.productsObject).length,
        ItemNames: Object.values(this.productsObject)
          .map(o => o.name)
          .join(','),
      };
      console.info('klaviyo event', klaviyoPayload);
      await this.proService.publishClientEvent('Added to Cart', klaviyoPayload);
    } catch (error) {
      console.error(error);
    }

    this.navigationService.movePage(true);
    setTimeout(() => {
      const cartFlag = localStorage.getItem('evinature_cart');
      if (cartFlag) {
        this.basketUrl += '&layout=' + cartFlag;
      }
      this.sessionStorageService.setItem('PRODUCTS_SET', this.basketUrl);
      console.info('Log: final basket url', this.basketUrl);

      document.location.href = this.basketUrl;
    }, 100);
  }

  public updateProps() {
    this.onUpdate.next({
      questionId: this.question?._id,
      result: this.productsObject,
    });
  }

  /* offer email fix */

  popUpEmailFix = false;
  userEmail = '';
  invalidEmailUser = '';
  config = {
    class: 'modal-dialog',
    animated: true,
  };

  testEmailIssues() {
    const shouldOffer = this.sessionStorageService.getItem('PRODUCTS_SET');
    if (shouldOffer) {
      this.popUpEmailFix = true;
    }
  }

  offerEmailFix() {
    const userData = this.tokenService.decodeToken();
    this.invalidEmailUser = userData.email;
    this.modalRef = this.modalService.show(this.modalTemplate!, this.config);
  }

  public noScroll() {
    window.scroll({ top: 0, left: 0 });
  }

  public isValid(text: any) {
    if (!text) {
      return false;
    }

    const regularExpression =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(text).toLowerCase());
  }

  notValid = false;
  dialogResult = '';
  async updateEmailAndRetry() {
    if (this.isValid(this.userEmail)) {
      const userData = this.tokenService.decodeToken();

      const result = await this.proService.fixSingleUserEmail({ email: userData.email, newEmail: this.userEmail }, this.proService?.activePro?._id);

      if (result.result) {
        this.dialogResult = result.result;
      } else {
        // put the new token in
        this.sessionStorageService.setItem(SessionKeys.ProToken, result.token);

        this.buildBasketUrl();
        await this.handleResultAndRedirectToBasket();
      }
    }
  }

  closeDialog() {
    this.modalRef?.hide();
  }
}
