import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionKeys } from '@services/consts';
import { SessionStorageService } from '@services/sessionStorage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-factor-debugger',
  templateUrl: './factor-debugger.component.html',
  styleUrls: ['./factor-debugger.component.scss'],
})
export class FactorDebuggerComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public sessionStorageService: SessionStorageService,
  ) {
    this.subscriptions.add(
      this.route.params.subscribe(() => {
        this.timeline = this.sessionStorageService.getItem(SessionKeys.Timeline) || [];

        if (this.timeline) {
          this.timeline = this.timeline.filter((step: any) => step !== null);
        }

        const factorsObject = this.sessionStorageService.getItem<any>(SessionKeys.Factors) || {};
        if (factorsObject) {
          const theFactors = this.getFactors(factorsObject);
          this.factors = Object.keys(theFactors).map((key: string) => {
            return { name: key, value: theFactors[key] };
          });
        }

        const productsObject = this.sessionStorageService.getItem<any>(SessionKeys.Products) || {};
        if (productsObject) {
          this.products = Object.keys(productsObject).map((key: string) => {
            return { name: key, value: productsObject[key] };
          });
        }
      }),
    );
  }

  getFactors(factorsMap: any) {
    const factorMap: Record<string, number> = {};
    Object.keys(factorsMap).forEach(key => {
      Object.keys(factorsMap[key]).forEach((idKey: number | string) => {
        factorMap[key] = factorMap[key] || 0;
        factorMap[key] += factorsMap[key][idKey];
      });
    });

    return factorMap;
  }

  timeline: any;

  factors: any;

  products: any;

  ngOnInit(): void {}

  @Input('proId') proId = '';

  public subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public navigateToStep(index: number) {
    return this.router.navigate([`/pro/${this.proId}/step/${index}`], { queryParams: { dir: 1, enter: 1, debug: 1 } });
  }
}
