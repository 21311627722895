import { Pipe, PipeTransform } from '@angular/core';
import { RuleOperators } from '@models/pro.model';

@Pipe({
  name: 'operator',
})
export class OperatorPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case RuleOperators.like:
        return 'containing';
      case RuleOperators.nlike:
        return '!containing';
      case RuleOperators.eq:
        return '=';
      case RuleOperators.neq:
        return '!=';
      case RuleOperators.lt:
        return '<';
      case RuleOperators.gt:
        return '>';
      case RuleOperators.bt:
        return '<=>';
      case RuleOperators.bte:
        return '<==>';
    }
    return 'na';
  }
}
