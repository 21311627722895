import { Injectable } from '@angular/core';
import { BaseService } from '@base/base.service';
import { Treatment } from '@models/treatment.model';

@Injectable({
  providedIn: 'root',
})
export class TreatmentService extends BaseService<Treatment> {
  serviceUrl = '/api/treatments';
}
