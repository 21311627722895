import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ProService } from './pro.service';
import { SessionKeys } from './consts';
import { ResultsService } from './results.service';
import { ProLocalResultService } from './pro-local-result.service';
import { SessionStorageService } from './sessionStorage.service';

@Injectable()
export class StarterService {
  constructor(
    private resultsService: ResultsService,
    private proResultsService: ProLocalResultService,
    private proService: ProService,
    public sessionStorageService: SessionStorageService,
  ) {}

  async freshStart(proId: string) {
    if (proId) {
      const token = uuidv4();
      this.resultsService.setToken(token);

      console.info('Log: Starter');
      this.sessionStorageService.setItem(SessionKeys.Starter, 'started');

      const resultEncrypted = await this.proService.setResultData(proId, { token });
      const elasticRecordId = resultEncrypted.elasticId;
      if (elasticRecordId) {
        this.sessionStorageService.setItem(SessionKeys.Elastic, elasticRecordId);
      }

      const resultWithToken = await this.proResultsService.setResultData(proId, { token, tokenize: true });
      const mongoRecordId = resultWithToken.mongoId;
      if (mongoRecordId) {
        this.sessionStorageService.setItem(SessionKeys.Mongo, mongoRecordId);
      }
    }
  }
}
