import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify({ value }));
  }

  getItem<T>(key: string, otherwise?: T): T | null {
    const data: string | null = localStorage.getItem(key);

    if (data !== null) {
      try {
        return JSON.parse(data).value;
      } catch (error) {
        return data as unknown as T;
      }
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  public updateItem(key: string, update: Record<string, unknown>) {
    const existingItem = this.getItem(key) || {};
    Object.assign(existingItem, update);
    this.setItem(key, existingItem);
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }
}
