/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { environment } from '@environments/environment';
import { DirtyService } from '@services/dirty.service';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { LoaderService } from '@services/loader.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-select-many',
  templateUrl: './select-many.component.html',
  styleUrls: ['./select-many.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SelectManyComponent extends PropertiesBaseComponent implements OnChanges {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    public gtmService: GoogleTagManagerService,
    public loaderService: LoaderService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);
    this.canContinue = false;
    this.subscriptions.add(
      this.onLoad.subscribe(async (question): Promise<void> => {
        this._properties.text1 = this._properties.text1 || 'Continue';
        if (this._properties.sourceDimension) {
          const priorData = await this.resultsService.getResultsForDimension(this._properties.sourceDimension);
          this.optionsArray = priorData;
        } else {
          this.optionsArray = this._properties.options;
        }
        const enteredValue = this.resultsService.getResultsForQuestion(question._id);

        if (enteredValue && Array.isArray(enteredValue)) {
          this.pagingService.configure({ next: true, prev: true });

          enteredValue.forEach(item => {
            this.optionsArray[item.index].value = true;
          });
        }
        this.loaderService.isReady();
      }),
    );

    const subscription = this.keyServiceRef.keyEventObs.subscribe((keys: string) => {
      switch (keys) {
        case 'k-ArrowDown':
          if (this.selectedIndex < this.optionsArray.length - 1) {
            this.selectedIndex++;
            this.clearActive();
            this.optionsArray[this.selectedIndex].isActive = true;
          }
          break;
        case 'k-ArrowUp':
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
          }
          this.clearActive();
          this.optionsArray[this.selectedIndex].isActive = true;
          break;
        case 'k-Space':
          this.optionsArray[this.selectedIndex].value = true;
          this.updateProps();
          break;
        case 'k-Enter':
          if (this.updateProps()) {
            this.resultsService.nextPage();
          }
          break;
      }
    });

    this.subscriptions.add(subscription);
  }

  public selectedIndex = -1;

  public values: any = {};

  notValid = false;

  public optionsArray: any[] = [];

  public usedQuestions = {};

  public rulesDictionary = {};

  public clearActive() {
    this.optionsArray.forEach((option: any) => {
      delete option.isActive;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  handleOptionClick(i: number) {
    this.selectedIndex = i;
    if (this._properties.options[i].exclusive) {
      this.optionsArray.forEach((option, index) => {
        if (i !== index) {
          option.value = null;
        }
      });
    } else {
      this.optionsArray.forEach((option, index) => {
        if (option.exclusive) {
          option.value = null;
        }
      });
    }

    let valid = false;
    for (const option of this.optionsArray) {
      console.info('Log: test', option);
      if (option) {
        console.info('Log: dimension tested and found', option);
        valid = true;
        break;
      }
    }
    console.info('Log: set next to', valid);
    this.pagingService.configure({ next: valid, prev: true });
  }

  setActiveOptionHighlight(index: number) {
    this.optionsArray.forEach(item => {
      delete item._highlight;
    });
    this.optionsArray[index]._highlight = true;
  }

  moveNext() {
    if (this.updateProps()) {
      if (this.question?.ga_event && environment.production) {
        this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
      }
      this.resultsService.nextPage();
    }
  }

  public updateProps() {
    const retValues: any[] = [];

    this.optionsArray.forEach((option: any, index: number) => {
      if (option.value) {
        retValues.push({ index, label: option.label });
      }
    });

    if (retValues.length > 0) {
      this.onUpdate.next({
        questionId: this.question?._id,
        result: retValues,
      });
      this.notValid = false;
      return true;
    }
    this.notValid = true;
    this.pagingService.configure({ next: false, prev: true });
    return false;

    // this.onUpdate.next(retValues);
  }
}

interface QuestionOption {
  index: number;
  value: number;
  label: string;
}
