import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAdaptHeight]',
})
export class AdaptHeightDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('appAdaptHeight') appAdaptHeight = 0;

  constructor(private el: ElementRef) {}

  async ngOnInit() {
    const view = this.viewport();
    this.el.nativeElement.style.height = `${view.height - this.appAdaptHeight}px`;
    this.el.nativeElement.style.overflowY = 'auto';
    // this.el.nativeElement.style.overflowX = 'auto';
    this.adapt();
  }

  adapt() {
    setTimeout(() => {
      const view = this.viewport();
      this.el.nativeElement.style.height = `${view.height - this.appAdaptHeight}px`;
      this.el.nativeElement.style.overflowY = 'auto';
      // this.el.nativeElement.style.overflowX = 'hidden';
      this.adapt();
    }, 1000);
  }

  viewport() {
    let e: any = window;
    let a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  }
}
