import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';

@Injectable()
export class BaseService<T = any> {
  constructor(private http: HttpClient) {}

  public serviceUrl = '/api/';

  public items: T[] = [];

  public cacheSubscription?: Subscription;

  public create(item: T): Promise<any> {
    return firstValueFrom(this.http.post<T>(this.serviceUrl, item));
  }

  public update(item: T): Promise<any> {
    return firstValueFrom(this.http.put<T>(this.serviceUrl, item));
  }

  public query(): Promise<any> {
    return firstValueFrom(this.http.get<T[]>(this.serviceUrl));
  }

  public delete(id: string): Promise<any> {
    return firstValueFrom(this.http.delete<any[]>(`${this.serviceUrl}/${id}`));
  }

  public get(id: string): Promise<any> {
    return firstValueFrom(this.http.get<any[]>(`${this.serviceUrl}/${id}`));
  }
}
