import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemBaseComponent } from '@base/item-base.component';
import { DirtyService } from '@services/dirty.service';
import { NavigationService } from '@services/navigation.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { ProService } from '@services/pro.service';
import { LoaderService } from '@services/loader.service';
import { TrackingService } from '@services/tracking.service';
@Component({
  selector: 'app-pro-step-form',
  templateUrl: './pro-step-form.component.html',
  styleUrls: ['./pro-step-form.component.scss'],
})
export class ProStepFormComponent extends ItemBaseComponent implements OnInit, OnDestroy {
  lastIndex = 0;

  subscriptions: Subscription = new Subscription();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    private proService: ProService,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public navigationService: NavigationService,
    public pagingService: PagingService,
    public loaderService: LoaderService,
    public trackingService: TrackingService,
  ) {
    super(_ngZone, dirtyService, changeDetectorRef, router, route);
    this.DataController = this.proService;

    this.subscriptions.add(
      this.proService.onStepChanged$.subscribe(params => {
        console.info('Log: pro service subscription', params);
        this.proId = params.id;
        this.data = this.proService.activePro;
        this.activeStepIndex = params.step_id;
        this.activeStep = this.proService.getStep(this.activeStepIndex);
        console.info('Log: set active step', this.activeStep);
      }),
    );

    this.subscriptions.add(
      this.loaderService.curtain$.subscribe(loadingState => {
        this.curtainOn = loadingState;
      }),
    );

    this.dirtyService.dirty.subscribe(isDirty => {
      this.dirty = isDirty;
    });
  }

  taboola() {
    try {
      if (Number(this.activeStepIndex) >= this.data.steps.length - 1) {
        try {
          (window as any)._tfa.push({ notify: 'event', name: 'endofpro', id: 1439893 });
        } catch (error) {}
      }
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnInit(): Promise<void> {
    this.notReady = false;
    this.proId = this.route.snapshot.paramMap.get('id') || '';
    if (!this.proService.activePro) {
      this.data = await this.proService.load(this.proId, this.activeStepIndex);
    } else {
      this.data = this.proService.activePro;
    }
    this.activeStepIndex = +(this.route.snapshot.paramMap.get('step_id') || 0);

    this.activeStep = this.proService.getStep(this.activeStepIndex);
    this.dirty = true;
    try {
      this.trackingService.trackStart(this.activeStep, this.activeStepIndex);
      this.taboola();
    } catch (error) {
      console.error('error', error);
    }
  }

  public proId = '';

  public dirty = true;

  public data: any;

  public activeStep: any;

  public activeStepIndex = 0;

  notReady = false;
  curtainOn = true;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  movePaging(data: any) {
    console.info('Log: movePaging');
    this.navigationService.movePage(true);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async updateStepValidity(stepData: any) {
    this.resultsService.activeData = stepData;
    if (stepData && (stepData.errors || stepData.result === undefined)) {
      this.resultsService.isValid.next(false);
    } else {
      this.resultsService.isValid.next(true);
      this.dirty = false;
    }
  }
}
