import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseService } from '@base/base.service';
import { DirtyService } from './services/dirty.service';
import { KeyPressDistributionService } from './services/keypress-distribution.service';
import { PagingService } from './services/paging.service';
import { ResultsService } from './services/results.service';
import { NavigationService } from './services/navigation.service';
import { ConsoleService } from './services/console.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoaderService } from './services/loader.service';
import { MomentModule } from 'ngx-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimelineService } from './services/timeline/timeline.service';
import { FactorsService } from './services/timeline/factors.service';
import { TokenService } from './services/token.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthInterceptor } from './auth/auth.intercepter';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { StarterService } from './services/starter.service';
import { TreatmentService } from './services/treatment.service';
import { ProLocalResultService } from '@services/pro-local-result.service';
import { ProService } from '@services/pro.service';
import { ProRouterService } from '@services/pro-router.service';
import { ConditionService } from '@services/condition.service';
import { SlidesModule } from './slides/slides.module';
import { SharedModule } from './shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '@services/timeline/products.service';
import { TrackingService } from '@services/tracking.service';
import { DownTimeComponent } from './down-time/down-time.component';
import { AppConfigService } from '@services/config.service';
import { UnderConstructionGuard } from './guards/under-construction.guard';
import { SessionStorageService } from '@services/sessionStorage.service';
import { LocalStorageService } from '@services/localStorage.service';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';

const appInitializerFn = (appConfig: AppConfigService) => {
  return async () => {
    await appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [AppComponent, DownTimeComponent, LoginDialogComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MomentModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatCardModule,
    // NgbModule,
    DragDropModule,
    ModalModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-5XPVJ2H',
    }),
    SlidesModule,
    NgbModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    UnderConstructionGuard,
    AppConfigService,
    ConditionService,
    ProService,
    ProRouterService,
    BaseService,
    ResultsService,
    ProLocalResultService,
    DirtyService,
    ConsoleService,
    PagingService,
    NavigationService,
    KeyPressDistributionService,
    LoaderService,
    TimelineService,
    FactorsService,
    ProductsService,
    TokenService,
    AuthGuardService,
    StarterService,
    TreatmentService,
    TrackingService,
    SessionStorageService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
