import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Rule } from '@models/pro.model';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { Question } from '@models/question.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '@environments/environment';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-text-conditional',
  templateUrl: './text-conditional.component.html',
  styleUrls: ['./text-conditional.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TextConditionalComponent extends PropertiesBaseComponent implements OnChanges, OnInit {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    public sanitizer: DomSanitizer,
    public gtmService: GoogleTagManagerService
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);
    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      switch (keys) {
        case 'k-Enter':
          this.resultsService.nextPage();
          break;
      }
    });

    this.pagingService.configure({ next: true, prev: true });
    this.subscriptions.add(subscription);
  }

  public results: Record<string, any> = {};

  public sanitizedProperties: any = {};

  protected override loaded(question: Question) {
    this.sanitizedProperties.text1 = this.sanitizer.bypassSecurityTrustHtml(question?.properties.text1);
    this.sanitizedProperties.text2 = this.sanitizer.bypassSecurityTrustHtml(question?.properties.text2);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  activeOption: any;

  public ngOnInit() {
    this.results = this.resultsService.getFactors();

    (this._rules as unknown as Rule[][]).forEach((item, index) => {
      const result = this.resultsService.processRulesGetResults(item, {
        result: this.results,
      });
      if (result) {
        this.activeOption = this._properties.options[index];
        this.sanitizedProperties.info = this.sanitizer.bypassSecurityTrustHtml(this.activeOption.info);
        this.sanitizedProperties.label = this.sanitizer.bypassSecurityTrustHtml(this.activeOption.label);
      }
    });
    // this.resultsService.nextPage.next();
  }

  @Output() public paging: EventEmitter<boolean> = new EventEmitter();

  moveNext() {
    if (this.question?.ga_event && environment.production) {
      this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
    }
    this.resultsService.nextPage();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateProps(event: any) {
    // this.onUpdate.next({ questionId: this.question._id, result: this.stepValue });
  }
}
