import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@services/loader.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isReady();
    this.loaderService.showContent();
  }
}
