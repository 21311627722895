import { AfterViewInit, Component } from '@angular/core';
import confetti from 'canvas-confetti';
@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
})
export class ConfettiComponent implements AfterViewInit {
  ngAfterViewInit() {
    return confetti({
      shapes: ['square'],
      particleCount: 100,
      spread: 90,
      origin: {
        y: 1,
        x: 0.5,
      },
    });
  }
}
