import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KeyPressDistributionService {
  private keyEventSubject = new Subject<string>();

  public keyEventObs: Observable<string> = this.keyEventSubject.asObservable();

  public distributeKeyPress(keyValue: KeyboardEvent): void {
    if (this.permitKey(keyValue)) {
      this.keyEventSubject.next(this.convertToString(keyValue));
    }
  }

  public permitKey(keyEvent: KeyboardEvent): boolean {
    const disallowedKeys = ['Shift', 'Control', 'Alt', 'Meta'];
    return !disallowedKeys.includes(keyEvent.key);
  }

  public convertToString(keyEvent: KeyboardEvent): string {
    const modifierKeys = ['altKey', 'ctrlKey', 'shiftKey'];
    let keyCode = 'k';
    for (const code of modifierKeys) {
      if ((keyEvent as any)[code]) {
        keyCode += code.substring(0, 1);
      }
    }
    return `${keyCode}-${keyEvent.code}`;
  }
}
