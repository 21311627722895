import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SessionKeys } from './consts';
import { StarterService } from './starter.service';
import * as JWT from 'jwt-decode';
import { SessionStorageService } from './sessionStorage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public serviceUrl = '/api/auth';

  constructor(
    private http: HttpClient,
    private starterService: StarterService,
    private sessionStorageService: SessionStorageService,
  ) {}

  public async create(item: Record<string, any>, force?: boolean): Promise<any> {
    const existingTokenStr = this.sessionStorageService.getItem(SessionKeys.ProToken);
    if (existingTokenStr && !force) {
      return;
    }

    const response: any = await firstValueFrom(this.http.patch(this.serviceUrl, item));
    if (response.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, response.token);
    } else {
      console.error('missing token5');
    }
  }

  public async loginOnly(options: { userRef?: string; tempLoginToken?: string }): Promise<any> {
    const response: any = await firstValueFrom(this.http.patch(this.serviceUrl, options));
    console.info('Log: login', response);
    if (response.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, response.token);
    } else {
      console.error('missing token6');
    }
    return response;
  }

  public async login(
    proId: string,
    options: {
      userRef?: string;
      tempLoginToken?: string;
    },
  ): Promise<any> {
    const response: any = await firstValueFrom(this.http.patch(this.serviceUrl + '/token', options));
    console.info('Log: login', response);
    if (response.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, response.token);
      await this.starterService.freshStart(proId);
    } else {
      console.error('missing token6');
    }
  }

  async patchToken(
    proId: string,
    options: {
      userRef?: string;
    },
  ) {
    const response: any = await firstValueFrom(this.http.patch(this.serviceUrl, options));
    if (response.token) {
      this.sessionStorageService.setItem(SessionKeys.ProToken, response.token);
      console.info('Log: patchToken', response);
      // await this.starterService.freshStart(proId, { ...response, tokenize: true });
    } else {
      console.error('missing token9');
    }
  }

  public async startOTPVerification(proId: string, email: string): Promise<any> {
    return firstValueFrom(this.http.post(`api/pro-result/${proId}/otp`, { email }));
  }

  public async endOTPVerification(proId: string, otpCode: string, email: string): Promise<any> {
    return firstValueFrom(this.http.patch(`api/pro-result/${proId}/otp`, { otpCode, email }));
  }

  decodeToken(): any {
    try {
      const token = this.sessionStorageService.getItem<string>(SessionKeys.ProToken);
      if (token) {
        return JWT.jwtDecode(token);
      }
    } catch (Error) {
      return null;
    }
    return null;
  }
}
