<div class="debugger">
  <ul>
    <li *ngFor="let factor of factors">
      {{ factor.name }}
      <span class="badge">{{ factor.value }}</span>
    </li>
  </ul>

  <ul>
    <li *ngFor="let product of products">
      {{ product.value.name }}

      <span class="badge">{{ product.value.dosagePills }} / {{ product.value.quantity }} </span>
    </li>
  </ul>

  <ul>
    <li *ngFor="let step of timeline">
      <div (click)="navigateToStep(step.index)" *ngIf="step" class="badge clickable">
        {{ step.index }}
        <div *ngIf="step.ops && step.ops.factors.length">
          <div *ngFor="let factor of step.ops.factors">
            {{ factor.factor }} | {{ factor.prev }} +
            {{ factor.delta }}
          </div>
        </div>

        <div *ngIf="step.ops && step.ops.products.length">
          <div *ngFor="let product of step.ops.products">
            {{ product | json }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
