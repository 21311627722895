import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { environment } from '@environments/environment';
import { DirtyService } from '@services/dirty.service';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { LoaderService } from '@services/loader.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-select-one',
  templateUrl: './select-one.component.html',
  styleUrls: ['./select-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SelectOneComponent extends PropertiesBaseComponent implements OnChanges, AfterContentInit {
  modalRef?: BsModalRef;

  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    private modalService: BsModalService,
    public gtmService: GoogleTagManagerService,
    public loaderService: LoaderService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    this.resultsService.isValid.next(false);

    this.subscriptions.add(
      this.onLoad.subscribe(question => {
        const enteredValue = this.resultsService.getResultsForQuestion(question._id);
        this.EnteredValue = enteredValue;
        if (enteredValue) {
          console.info('Log: enteredValue', enteredValue);
          this.pagingService.configure({ next: true, prev: true });
        }

        this.optionsArray = this._properties.options;
        console.info('Log:', this.optionsArray);
        //  this.updateProps({});
      }),
    );

    this.containerRef?.nativeElement.scroll(0, 0);
    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      switch (keys) {
        case 'k-ArrowDown':
          if (this.selectedIndex < this.optionsArray.length - 1) {
            this.selectedIndex++;
            if (this.optionsArray[this.selectedIndex]) {
              this.EnteredValue = this.optionsArray[this.selectedIndex].label;
              this.setValue(this.selectedIndex, this.EnteredValue);
              this.containerRef?.nativeElement.scroll(0, 150 * this.selectedIndex);
            }
          }
          break;
        case 'k-ArrowUp':
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
          }
          if (this.optionsArray[this.selectedIndex]) {
            this.EnteredValue = this.optionsArray[this.selectedIndex].label;
            this.setValue(this.selectedIndex, this.EnteredValue);
            this.containerRef?.nativeElement.scroll(0, 150 * this.selectedIndex);
          }
          break;
        case 'k-Enter':
          if (this.setValue(this.selectedIndex, this.EnteredValue)) {
            this.resultsService.nextPage();
          }
          break;
      }
    });
    this.subscriptions.add(subscription);
  }

  @ViewChild('inputData') public inputData?: ElementRef;

  @ViewChild('container') containerRef?: ElementRef;

  @ViewChild('template') modalTemplate?: TemplateRef<any>;

  config = {
    class: 'modal-dialog',
    animated: false,
  };

  openModal() {
    this.modalRef = this.modalService.show(this.modalTemplate!, this.config);
  }

  activeTextBubble: { countdown: 0; prefix: string; info: string } | null = null;

  notValid = false;

  public selectedIndex = -1;

  public EnteredValue: any;

  public optionsArray: QuestionOption[] = [];

  public ngAfterContentInit() {
    this.loaderService.isReady();
    this.inputData?.nativeElement.focus();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateChange(data: any) {
    this.clearDirty();
  }

  getCol(index: number): string {
    if (this._properties.columnType && Number(this._properties.columnType) > 1) {
      return 'col-' + Math.floor(this.optionsArray.length / Number(this._properties.columnType) + index);
    }
    return 'col-1';
  }

  chooseNextMove() {
    if (this._properties.autoContinue !== 'false') {
      this.moveNext();
    } else {
      this.closeDialog();
    }
  }

  public clickProxy($event: Event, index: number) {
    $event.stopPropagation();
    const value = this._properties.options[index].label;
    this.setValue(index, value);

    if (value) {
      this.canContinue = true;
    }

    this.activeTextBubble = this._properties.options[index];
    setTimeout(() => {
      if (this.activeTextBubble?.info) {
        this.openModal();
      } else if (!this._properties.text1) {
        this.moveNext();
      }
    }, 100);
  }

  closeDialog() {
    this.modalService.hide();
  }

  public setValue(index: number, value: any): boolean {
    this.selectedIndex = index;
    this.EnteredValue = value;

    if (value) {
      const exceptions = (this.optionsArray[index] as any).exceptions;
      this.onUpdate.next({ questionId: this.question?._id, index, result: value, exceptions });
      this.notValid = false;
      return true;
    }
    this.notValid = true;
    return false;
  }

  closeBtnVisible = false;

  showCloseBtn(show = true) {
    this.closeBtnVisible = show;
  }

  moveNext() {
    this.modalService.hide();
    if (this.setValue(this.selectedIndex, this.EnteredValue)) {
      if (this.question?.ga_event && environment.production) {
        this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
      }
      this.resultsService.nextPage();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateProps(event: any) {
    if (this.selectedIndex) {
      const exceptions = (this.optionsArray[this.selectedIndex] as any).exceptions;
      this.onUpdate.next({ questionId: this.question?._id, index: this.selectedIndex, result: this.EnteredValue, exceptions });
    }
  }
}

interface QuestionOption {
  index: number;
  value: number;
  label: string;
}
