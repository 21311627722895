import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, provideRouter, withRouterConfig } from '@angular/router';
import { ProStepFormComponent } from './components/pro-step-form/pro-step-form.component';
import { CustomReuseStrategy } from './route-reuse-strategy';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ProStepComponent } from './slides/pro-step/pro-step.component';
import { DownTimeComponent } from './down-time/down-time.component';
import { environment } from '@environments/environment';
import { UnderConstructionGuard } from './guards/under-construction.guard';

const redirectionObject = !environment.isActive
  ? {
      redirectTo: 'down-time',
    }
  : {};

const routes: Routes = [
  {
    path: 'down-time',
    component: DownTimeComponent,
  },

  { path: '', component: WelcomeComponent, canActivate: [UnderConstructionGuard] },
  {
    path: 'pro/:id/step/:step_id',
    component: ProStepComponent,
    pathMatch: 'full',
    ...redirectionObject,
    children: [{ path: '', component: ProStepFormComponent }],
    canActivate: [UnderConstructionGuard],
  },
  {
    path: 'pro/:id/preview/:question_id',
    component: ProStepComponent,
    pathMatch: 'full',
    ...redirectionObject,
    children: [{ path: '', component: ProStepFormComponent }],
    canActivate: [UnderConstructionGuard],
  },

  // while using the "if" method:
  /* {
    path: '**',
    redirectTo: defaultRoute,
  }, */
  {
    path: '**',
    redirectTo: 'down-time',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  providers: [
    provideRouter(routes, withRouterConfig({ urlUpdateStrategy: 'deferred' })),
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
