<div *ngIf="question">
  <div class="centered" *ngIf="gotProducts === true">
    <div *ngIf="question && activeOption !== undefined" class="centered">
      <p class="title3" [innerHTML]="activeOption._label"></p>
      <div [innerHTML]="activeOption._video" class="title4"></div>
    </div>

    <a
      target="_top"
      (click)="handleResultAndRedirectToBasket()"
      id="actionButton"
      class="btn btn-primary btn-lg"
      [innerHTML]="_properties.buttonText || 'Continue'"
      style="display: block !important; margin: 0 auto"
      >Continue to store</a
    >

    <br />
    <a target="_top" *ngIf="popUpEmailFix" (click)="offerEmailFix()" id="actionButton" class="btn btn-primary btn-lg" style="display: block !important; margin: 0 auto"
      >I've had an Email Issue</a
    >
  </div>
  <div class="centered" *ngIf="gotProducts === false">
    <div class="title4" [innerHTML]="_properties.noProductsText"></div>

    <a
      target="_top"
      [href]="_properties.noProductsUrl"
      class="btn btn-primary btn-lg"
      id="actionButton"
      [innerHTML]="_properties.noProductsButtonText || 'Continue'"
      style="display: block !important; margin: 0 auto"
      >Continue to store no products</a
    >
  </div>
</div>

<div *ngIf="debugMode" style="margin-bottom: 150px">
  <div *ngIf="medsExceptions && medsExceptions.length" class="gotMedsException">
    Can't use products:

    <span
      *ngFor="let exceptionProduct of medsExceptions"
      class="badge"
      [ngClass]="{ 'bg-warning': exceptionProduct.type === 'product', 'bg-info': exceptionProduct.type === 'exception' }"
    >
      {{ exceptionProduct.value.name }}
    </span>
  </div>

  <div class="row">
    <div class="col-8">
      <div class="form-group">
        <div class="thead-dark">Rule line expressions ></div>
      </div>

      <div *ngFor="let ruleset of debugRules; let i = index" class="rules-group">
        <strong *ngIf="_properties.options[i]">{{ i }}. {{ _properties.options[i].label }} </strong>
        <strong *ngIf="!_properties.options[i]">{{ i }}. {{ _properties.options[i] | json }}</strong>
        <div>
          <div *ngIf="ruleset.rules">
            <div *ngFor="let subrule of ruleset.rules; let x = index" class="rules-table" [ngClass]="{ applicable: subrule.applicable }">
              <div *ngFor="let factor of factors; let factorIndex = index">
                <div class="row" *ngIf="subrule.factors[factor]">
                  <div class="col-2">{{ factor }}</div>
                  <div class="col-4" *ngIf="subrule.factors && subrule.factors[factor]">
                    {{ subrule.factors[factor].operatorValue }}

                    {{ subrule.factors[factor].operatorSymbol | operator }}

                    <ng-container class="col-3" *ngIf="subrule.factors && subrule.factors[factor] && subrule.factors[factor].operatorSymbol?.startsWith('bt')">
                      {{ subrule.factors[factor].operatorValue2 }}
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="ruleset.length">
              <div *ngFor="let subrule of ruleset; let x = index" class="rules-table" [ngClass]="{ applicable: ruleset.applicable }">
                <div *ngFor="let factor of factors; let factorIndex = index">
                  <div class="row" *ngIf="subrule.factors[factor] && subrule.factors[factor].operatorValue">
                    <div class="col-2">{{ factor }}</div>
                    <div class="col-4" *ngIf="subrule.factors && subrule.factors[factor]">
                      {{ subrule.factors[factor].operatorValue }}
                      {{ subrule.factors[factor].operatorSymbol | operator }}
                      <ng-container *ngIf="subrule.factors && subrule.factors[factor] && subrule.factors[factor].operatorSymbol?.startsWith('bt')">
                        {{ subrule.factors[factor].operatorValue2 }}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="debugger-right">
        <div *ngFor="let factor of factors" class="row">
          <div class="col-6">{{ factor }}</div>
          <div class="col-6">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="factorResults[factor]" (change)="calculate(factorResults)" />
          </div>
        </div>
      </div>
    </div>

    <!-- Taboola Pixel Code -->
    <script>
      _tfa.push({ notify: 'event', name: 'endofpro', id: 1439893 });
    </script>
    <!-- End of Taboola Pixel Code -->
  </div>
</div>

<ng-template #offerEmailFixDialog>
  <a (click)="closeDialog()" class="close-button" id="dialog-close-btn">
    <img src="../../../../assets/icons/close.svg" class="Group-74" alt="" />
  </a>
  <div class="modal-body">
    <div class="center">
      <p class="dialog-text">
        It looks like the email:<br />
        <strong> "{{ invalidEmailUser }}"</strong><br />
        is not valid. Please Type your <strong>valid</strong> email address.
      </p>
      <input
        name="inputData"
        (focus)="noScroll()"
        [(ngModel)]="userEmail"
        (keyup)="updateProps()"
        (mouseup)="updateProps()"
        [placeholder]="_properties.placeholder || ''"
        #inputData
        type="email"
        class="form-control form-control-lg form-email"
        [email]="true"
      />

      <div *ngIf="notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Is this your email?</div>
      <div *ngIf="dialogResult" class="nice-error"><em class="icon-exclamation-circle"></em> {{ dialogResult }}</div>

      <button id="dialogActionButton" class="btn btn-primary btn-lg" (click)="updateEmailAndRetry()" [innerHTML]="'Update Email'"></button>
    </div>
  </div>
</ng-template>
