import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control?.dirty || control?.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-age-relate',
  templateUrl: './age-relate.component.html',
  styleUrls: ['./age-relate.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AgeRelateComponent extends PropertiesBaseComponent implements OnChanges, AfterViewInit {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);
    this.subscriptions.add(
      this.onLoad.subscribe(async (question): Promise<void> => {
        const enteredValue = this.resultsService.getResultsForQuestion(question._id);
        this.EnteredValue = enteredValue;
        if (this._properties.inputType === 'multiline') {
          this.notValid = false;
          this.canContinue = true;
        }
      }),
    );

    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      this.inputData?.nativeElement.focus();
      switch (keys) {
        case 'k-Enter':
          if (!this.EnteredValue) {
            this.notValid = true;
          }

          if (this.updateProps()) {
            this.resultsService.nextPage();
          }
          break;
      }
    });

    this.subscriptions.add(subscription);
  }

  age: any;

  rawAge: any;

  calculateAgeExpression() {}

  notValid = false;

  // @ViewChild('inputData') inputData: any;
  @ViewChild('inputData') public inputData?: ElementRef;

  public matcher = new MyErrorStateMatcher();

  public EnteredValue: any;

  public ngAfterViewInit() {
    this.inputData?.nativeElement.focus();
    this.updateProps(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  public ngOnInit() {}

  public isValid(text: any) {
    if (!text) {
      return false;
    }
    return Number.isInteger(Number(text));
  }

  @Output() public paging: EventEmitter<boolean> = new EventEmitter();

  moveNext() {
    if (this.updateProps()) {
      this.resultsService.nextPage();
    }
  }

  public updateProps(silent = false) {
    const validationResult = this.isValid(this.EnteredValue);
    if (validationResult) {
      const finalValue = this.age - Number(this.EnteredValue);
      this.notValid = false;
      this.onUpdate.next({
        questionId: this.question?._id,
        result: finalValue,
      });

      return true;
    } else {
      this.onUpdate.next({
        errors: this.EnteredValue,
      });
      if (!silent) {
        this.notValid = true;
      }
      return false;
    }
  }
}
