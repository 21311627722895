<!-- <div class="flex no-border">
   <select name="input_day" class="form-control" [(ngModel)]="day" #input_day (change)="update('day', day)">
    <option *ngFor="let day of days" [ngValue]="day">{{ day }}</option>
  </select>
  <select name="input_month" class="form-control" [(ngModel)]="month" #input_month (change)="update('month', month)">
    <option *ngFor="let month of months; let i = index" [ngValue]="i + 1">{{ month }}</option>
  </select>
</div> -->
<select name="input_year" class="form-dropdown form-select" [(ngModel)]="year" #input_year (change)="update('year', year)">
  <option *ngFor="let year of years" #input_year [value]="year">{{ year }}</option>
</select>
