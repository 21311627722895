import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { SessionKeys } from '../consts';
import { SessionStorageService } from '@services/sessionStorage.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    private http: HttpClient,
    public sessionStorageService: SessionStorageService,
  ) {}

  emitter: EventEmitter<any> = new EventEmitter();

  public serviceUrl = '/api/products';

  public fromServer(): Observable<any> {
    return this.http.get<any[]>(this.serviceUrl);
  }

  private _productsMap: Record<string, any> = {};

  public reset() {
    this.sessionStorageService.removeItem(SessionKeys.Products);
    this._productsMap = {};
  }

  private persist() {
    this.sessionStorageService.setItem(SessionKeys.Products, this._productsMap);
  }

  allProducts = [];

  getProduct(id: string): any {
    return this.allProducts.find((p: any) => p._id === id);
  }

  public async load() {
    this._productsMap = this.sessionStorageService.getItem<any>(SessionKeys.Products) || {};

    // load products
    if (this.allProducts.length === 0) {
      this.allProducts = await firstValueFrom(this.fromServer());
    }
  }

  addToProducts(productKey: string, product: any) {
    this._productsMap[productKey] = product;
    this.persist();
  }

  getProducts() {
    return this._productsMap;
  }
}
