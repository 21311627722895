import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { ProStepComponent } from '../slides/pro-step/pro-step.component';
import { DatePickerComponent } from './misc/date-picker/date-picker.component';
import { ProgressProComponent } from './slide-elements/progress-pro/progress-pro.component';
import { ProResultsComponent } from './debug/pro-results/pro-results.component';
import { FactorDebuggerComponent } from './debug/factor-debugger/factor-debugger.component';
import { NavigationComponent } from './slide-elements/navigation/navigation.component';
import { ArraySortPipe } from '@pipes/sort.pipe';
import { AdaptHeightDirective } from '../directives/adapt-height';
import { SelectRangeComponent } from './misc/select-range/select-range.component';
import { TextBubbleComponent } from './misc/text-bubble/text-bubble.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SanitizeHtmlPipe } from '@pipes/sanitizer.pipe';
import { ConfettiComponent } from './misc/confetti/confetti.component';
import { CountdownComponent } from './misc/countdown/countdown.component';
import { SharedModule } from '../shared.module';
import { OperatorPipe } from '@pipes/operator.pipe';
import { LineBreakPipe } from '@pipes/linebreak.pipe';

const declarations = [
  ProStepComponent,
  NavigationComponent,
  ProgressProComponent,
  ArraySortPipe,
  LineBreakPipe,
  ProResultsComponent,
  AdaptHeightDirective,
  DatePickerComponent,
  TextBubbleComponent,
  ConfettiComponent,
  SelectRangeComponent,
  CountdownComponent,
  FactorDebuggerComponent,
  WelcomeComponent,
  SanitizeHtmlPipe,
  OperatorPipe,
];
@NgModule({
  declarations,
  imports: [FormsModule, ReactiveFormsModule, RouterModule, CommonModule, HttpClientModule, BrowserModule, MomentModule, BrowserAnimationsModule, SharedModule],
  exports: declarations,
})
export class ComponentsModule {}
