import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

const stepsTime: Record<string, number> = {};
const intervals$: any = {};

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  public serviceUrl = '/api/pro-result';

  constructor(private http: HttpClient) {}

  public trackEnd(stepData: any, stepIndex: number) {
    const stringIndexKey = `_${stepIndex}`;
    console.info('track: clearInterval', intervals$[stringIndexKey], stepIndex);
    clearInterval(intervals$[stringIndexKey]);
    delete intervals$[stringIndexKey];
  }

  public trackStart(stepData: any, stepIndex: number) {
    const stringIndexKey = `_${stepIndex}`;
    console.info('track: trackStart', stringIndexKey, stepData);
    this.createInterval(stringIndexKey);
  }

  createInterval(stringIndexKey: string) {
    stepsTime[stringIndexKey] = 0;
    intervals$[stringIndexKey] = setInterval(function () {
      stepsTime[stringIndexKey]++;
    }, 1000);
  }

  public updateProTrend(proId: string, elasticRecordId: string, questionId: string, stepIndex: any) {
    const data = {
      [`Q_${stepIndex}`]: {
        stepIndex,
        questionId,
        time: stepsTime[`_${stepIndex.toString()}`],
      },
    };
    return firstValueFrom(this.http.patch(`${this.serviceUrl}/${proId}/trend/${elasticRecordId}`, data));
  }
}
