import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ResultsService } from '@services/results.service';
import { KeyPressDistributionService } from './services/keypress-distribution.service';
import { TokenService } from './services/token.service';
import { Subscription } from 'rxjs';
import { LoaderService } from '@services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private readonly tokenService: TokenService,
    private readonly keyService: KeyPressDistributionService,
    private readonly loaderService: LoaderService,
    private readonly resultsService: ResultsService,
  ) {
    this.notReady = true;
    this.subscriptions.add(
      this.loaderService.loading$.subscribe(loadingState => {
        this.notReady = loadingState;
      }),
    );
  }
  pendingValidation = false;
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  notReady = false;

  subscriptions: Subscription = new Subscription();

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(eventData: KeyboardEvent) {
    this.keyService.distributeKeyPress(eventData);
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.animation;
  }

  proResultsParams: any;
  async ngOnInit() {
    const tokenPayload: Record<string, any> = {};
    console.info('Log: create token from appComponent');
    // get proId & temp-login.
    const rUrl = document.location.search.split('?')[1];
    const pairs = rUrl?.split('&');
    const urlSnapshot: Record<string, string> = {};
    pairs?.forEach(pair => {
      const singles = pair.split('=');
      urlSnapshot[singles[0]] = singles[1];
    });

    if (urlSnapshot['t']) {
      this.proResultsParams = urlSnapshot;
      return;
    } else {
      await this.tokenService.create(tokenPayload); // can be forced
    }

    console.info('Log: token created in appComponent');
    // do we have a direction
    // this.clearDataForReset();
    await this.resultsService.load();
  }

  updateValidation(state: any) {
    this.pendingValidation = state;
  }
}
