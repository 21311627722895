<div *ngIf="question">
  <h3 [innerHTML]="_properties.text1"></h3>
  <div class="row radio-row">
    <div class="col-2">
      <div class="form-group">
        <div class="radio">
          <label>
            <input
              type="radio"
              #units
              [ngModelOptions]="{ standalone: true }"
              (click)="changeValues('metric')"
              [(ngModel)]="_properties.units"
              [checked]="_properties.units === 'metric'"
              value="metric"
            />
            <span class="label label-success">Metric</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <div class="radio">
          <label>
            <input
              type="radio"
              #units
              [ngModelOptions]="{ standalone: true }"
              (click)="changeValues('imperial')"
              [(ngModel)]="_properties.units"
              [checked]="_properties.units === 'imperial'"
              value="imperial"
            />
            <span class="label label-danger">Imperial</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div id="imperial" *ngIf="_properties.units === 'imperial'">
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="weight">Weight (lb):</label>
          <input
            type="number"
            [(ngModel)]="_properties.weight_lb"
            [ngModelOptions]="{ standalone: true }"
            (change)="calc()"
            (keyup)="calc()"
            #weight_lb
            placeholder="weight in lb"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="weight_foot">Height (foot):</label>
          <input
            type="number"
            [(ngModel)]="_properties.height_foot"
            [ngModelOptions]="{ standalone: true }"
            #weight_foot
            (change)="calc()"
            (keyup)="calc()"
            placeholder="height in foot"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="height_inch">Height (inch):</label>
          <input
            type="number"
            [(ngModel)]="_properties.height_inch"
            [ngModelOptions]="{ standalone: true }"
            #height_inch
            (change)="calc()"
            (keyup)="calc()"
            placeholder="height in inch"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="metric" *ngIf="_properties.units === 'metric'">
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="weight_kg">Weight (kg):</label>
          <input
            type="number"
            [(ngModel)]="_properties.weight_kg"
            [ngModelOptions]="{ standalone: true }"
            #weight_kg
            (change)="calc()"
            (keyup)="calc()"
            placeholder="weight in kg"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="height_cm">Height (cm):</label>
          <input
            type="number"
            [(ngModel)]="_properties.height_cm"
            [ngModelOptions]="{ standalone: true }"
            #height_cm
            (change)="calc()"
            (keyup)="calc()"
            placeholder="height in cm"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- Calculated BMI is shown here -->
  <h3 [innerHTML]="_properties.text2"></h3>

  <div class="well text-center">
    <h3 class="text-muted">Calculated BMI</h3>
    <h2>
      <span class="label text-{{ catClass }}" title="{{ catTitle }}">{{ _properties.bmi }}</span>
    </h2>
    <h4 class="text-muted">{{ catTitle }}</h4>
  </div>

  <div class="center">
    <button *ngIf="_properties.text3" id="actionButton" class="btn btn-primary btn-lg" [disabled]="!canContinue" (click)="moveNext()" [innerHTML]="_properties.text3"></button>
  </div>
</div>
