import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { SessionKeys } from '@services/consts';
import { SessionStorageService } from '@services/sessionStorage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor(public sessionStorageService: SessionStorageService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const idToken = this.sessionStorageService.getItem(SessionKeys.ProToken);
    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + idToken),
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
